import { Injectable } from '@angular/core';
import { FirebaseServerService } from '@services/firebase-server/firebase-server.service';
import { Material } from '@models/material.nodel';
import firebase from 'firebase/compat';

const collection = 'materials';

@Injectable({
  providedIn: 'root'
})
export class ServerMaterialsService {
  public db: firebase.firestore.Firestore;

  constructor(
      private fireServerSvc: FirebaseServerService,
  ) {
    this.db = fireServerSvc.getDb();
  }

  public async getMaterials(): Promise<any> {
    const ref = this.db.collection(collection)
        .orderBy('id', 'asc');
    const querySnapshot = await ref.get();
    return querySnapshot.docs.map(doc => ({...doc.data() as Material}));
  }
}
