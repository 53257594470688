import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  OK_STATUS,
  Subscription,
  SUBSCRIPTION_CHIP,
  SUBSCRIPTION_STATUS,
} from '@models/subscription.model';
import { Button } from '@models/general.model';
import { Address } from '@models/address.model';

@Component({
  selector: 'app-subscription-card',
  templateUrl: './subscription-card.component.html',
  styleUrls: ['./subscription-card.component.scss'],
})
export class SubscriptionCardComponent implements OnInit,  OnChanges {
  @Input() subscriptions: Subscription[] = [];
  @Input() button: Button = { show: false, text: '', icon: '' };
  @Input() showDisclaimer: boolean = false;

  public loadingPage: boolean = true;

  @Output() onClick = new EventEmitter();

  ngOnInit() {
    if (this.subscriptions.length >= 1)
      setTimeout(() => { this.loadingPage = false; }, 1000);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadingPage = true;
    if (this.subscriptions.length >= 1)
      setTimeout(() => { this.loadingPage = false; }, 1000);
  }

  public address(i: number) { return this.subscriptions[i].address as Address }
  public fullAddress(i: number) { return this.address(i).street + ',' + this.address(i).detail + ',' + this.address(i).commune }
  public status(status: string) { return SUBSCRIPTION_STATUS[status]; }
  public chip(status: string) { return SUBSCRIPTION_CHIP[status]; }
  public successfulStatus(status: string) { return OK_STATUS.indexOf(status) >= 0; }

  public async callToAction(id: string, type: string) {
    this.onClick.emit({ id, type });
  }
}
