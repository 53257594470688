import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { IonInput } from '@ionic/angular';

@Component({
  selector: 'app-money-input',
  templateUrl: './money-input.component.html',
  styleUrls: ['./money-input.component.scss'],
})
export class MoneyInputComponent {
  public inputModel = '';

  @ViewChild('ionInputEl', { static: true }) ionInputEl!: IonInput;
  @Output() onInputMoney = new EventEmitter();

  public onInput(ev) {
    const value = ev.target!.value;
    let filteredValue = value.replace(/[^0-9]+/g, '');
    filteredValue = parseFloat(filteredValue.toString().replace(/,/g, ''));
    filteredValue = Number.isNaN(filteredValue) ? 0 : filteredValue;
    filteredValue = filteredValue.toLocaleString('es-CL');
    filteredValue = '$' + filteredValue;
    this.ionInputEl.value = this.inputModel = filteredValue;
    this.onInputMoney.emit(filteredValue);
  }
}
