import { Component, Input, OnInit } from '@angular/core';
import { RouteFilterDayService } from '@services/firebase-server/route-filter-day/route-filter-day.service';
import { daysOptions } from '@constants/date.constants';

@Component({
  selector: 'app-information-of-day-card',
  templateUrl: './information-of-day-card.component.html',
  styleUrls: ['./information-of-day-card.component.scss'],
})
export class InformationOfDayCardComponent implements OnInit {
    @Input() commune: string = '';

    public filters: any;
    public day: string;
    public daysOfOptions = daysOptions;

    constructor(
        private routeFilterDaySvc: RouteFilterDayService,
    ) {}

    async ngOnInit () {
        this.filters = await this.routeFilterDaySvc.getFilters();
        const key = this.findKeyByName();
        this.day = this.daysOfOptions.find(day => day.key === key).name;
        this.day = this.day ? this.day : 'No encontrado';
    }

    private findKeyByName() {
        for (const item of this.filters) {
            const filterMatch = item.filter.find((filterItem: any) => filterItem.name.toUpperCase() === this.commune.toUpperCase());
            if (filterMatch) {
                return item.key;
            }
        }
        return null;
    }
}
