import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

@Injectable()
export class UtilsService {

  constructor () {
  }

  public validators(minLength?: number, maxLength?: number, required?: boolean, pattern?: any) {
    const validation = [];
    if (minLength) {
      validation.push(Validators.minLength(minLength));
    }
    if (maxLength) {
      validation.push(Validators.maxLength(maxLength));
    }
    if (required) {
      validation.push(Validators.required);
    }
    if (pattern) {
      validation.push(Validators.pattern(pattern));
    }
    return validation;
  }

  public remove(items, key, value) {
    return items.filter((item) => {
      return item[key] !== value;
    });
  }

  public filterBy(items, key, value) {
    return items.filter((item) => {
      return item[key] === value;
    });
  }

  public filterDuplicates(items, key): any[] {
    return [...new Map(items.map(item =>
      [item[key], item])).values()];
  }

  public sortByAttribute(array, ...attrs) {
    const predicates = attrs.map(pred => {
      const descending = pred.charAt(0) === '-' ? -1 : 1;
      pred = pred.replace(/^-/, '');
      return {
        getter: o => o[pred],
        descend: descending,
      };
    });
    return array.map(item => {
      return {
        src: item,
        compareValues: predicates.map(predicate => predicate.getter(item)),
      };
    })
      .sort((o1, o2) => {
        let i = -1, result = 0;
        while (++i < predicates.length) {
          if (o1.compareValues[i] < o2.compareValues[i]) result = -1;
          if (o1.compareValues[i] > o2.compareValues[i]) result = 1;
          if (result *= predicates[i].descend) break;
        }
        return result;
      })
      .map(item => item.src);
  }

  public capitalize(str: string, camel = true) {
    const newString = camel ? this.camelize(str) : str.toLowerCase();
    return newString.charAt(0).toUpperCase() + newString.slice(1);
  }

  public camelize(str: string) {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  }

  public cleanObj(obj) {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  }

  public cleanNumber(number: string, type: 'float' | 'int') {
    if (number === '#¡DIV/0!' || number === '#REF!' || number === '#DIV/0!' || number === undefined || number === '#N/A') return 0;
    number = number.replace('$', '')
    number = number.replace('.', '');
    number = number.replace(',', '.');
    return type === 'float' && number ? parseFloat(number) :
        type === 'int' && number ? parseInt(number) : 0;
  }

  public getScssUrl(url: string) {
    if (url) {
      return 'url(\'' + url.trim() + '\')';
    }
    return 'url(undefined)';
  }

  public cleanRut(rut: string) {
    return rut.replace(/\./g, '').replace(/-/g, '').replace(/^0+/, '').replace(/\s/g, '').toUpperCase().trim();
  }

  public differenceInDays(first: Date, second: Date) {
    const diffTime = Math.abs(second.getTime() - first.getTime());
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); 
    return diffDays
  }
}
