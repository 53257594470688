import { Injectable } from '@angular/core';
import { FirebaseServerService } from '@services/firebase-server/firebase-server.service';
import firebase from 'firebase/compat';

const collection = 'routeFilterDay';

@Injectable({
  providedIn: 'root'
})
export class RouteFilterDayService {
  public db: firebase.firestore.Firestore;

  constructor(
      private fireServerSvc: FirebaseServerService,
  ) {
    this.db = fireServerSvc.getDb();
  }

  public async getFilters(): Promise<any[]> {
    const ref = this.getQuery();
    const querySnapshot = await ref.get();
    return querySnapshot.docs.map(doc => ({...doc.data() as any, docId: doc.id}));
  }

  private getQuery() {
    return this.db.collection(collection);
  }
}
