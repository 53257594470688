import { Component, OnInit } from '@angular/core';
import { ExtraContentInterface } from '@models/extra-content.model';
import { ExtraContentService } from '@services/firebase/extra-content/extra-content.service';

@Component({
  selector: 'app-extra-content-slider',
  templateUrl: './extra-content-slider.component.html',
  styleUrls: ['./extra-content-slider.component.scss'],
})
export class ExtraContentSliderComponent implements OnInit {

  public loading = true;
  public extraContents: ExtraContentInterface[] = [];

  constructor(
    private extraContentSvc: ExtraContentService,
  ) {

  }

  async ngOnInit() {
    this.extraContents = await this.extraContentSvc.getFeaturedExtraContents() as any;
    this.loading = false;
  }

}
