import { Injectable } from '@angular/core';
import { FirebaseService } from '@services/firebase/firebase.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Service } from '@models/service.model';
import { map, Observable } from 'rxjs';

const collection: string = 'services';

@Injectable()
export class ServicesService {
    constructor (
        private fireSvc: FirebaseService,
        private afs: AngularFirestore,
    ) {
    }

    public createRef(col: string, docId: string) {
        return this.afs.collection(col).doc(docId).ref;
    }

    public async getService(id: string): Promise<Service> {
        const doc = await this.afs.collection(collection).doc(id).ref.get();
        if (!doc.exists) { console.error('No such document!'); }
        const document = { docId: doc.id, ...doc.data() as Service };
        await this._convertDoc(document);
        return document;
    }

    public async getServiceByUser(userId: string): Promise<Service[]> {
        const querySnapshot = await this.afs.collection(collection).ref
            .where('user.uuid', '==', userId)
            .get();
        return querySnapshot.docs.map(doc => ({...doc.data() as Service, docId: doc.id}));
    }

    public async getServiceBySubscription(subId): Promise<Service[]> {
        const subRef = this.createRef('subscriptions', subId);
        const querySnapshot = await this.afs.collection(collection).ref
            .where('punctualWithdrawal', '==', false)
            .where('subscription', '==', subRef).get();
        return querySnapshot.docs.map(doc => ({...doc.data() as Service, docId: doc.id}));
    }

    public async getPuntualServiceByUser(userId): Promise<Service[]> {
        const userRef = this.createRef('users', userId);
        const querySnapshot = await this.afs.collection(collection).ref
            .where('punctualWithdrawal', '==', true)
            .where('user', '==', userRef).get();
        const documents = querySnapshot.docs.map(doc => ({...doc.data() as Service, docId: doc.id}));
        for (let document of documents) { await this._convertDoc(document); }
        return documents;
    }

    public getPuntualServiceByUserObs(userId): Observable<Service[]> {
        const userRef = this.createRef('users', userId);
        const documents = this.afs.collection(collection, ref =>
            ref
                .where('punctualWithdrawal', '==', true)
                .where('user', '==', userRef)
                .orderBy('date', 'desc'),
        ).valueChanges({idField: 'docId'});
        return documents.pipe(map((services: Service[]) => services.map(service => service as Service)))
    }

    public async createService(data: Service): Promise<any> {
        return await this.afs.collection(collection).add(data)
            .then((docRef) => { return docRef.id; })
            .catch((error) => { return error; });
    }

    public async updateService(id: string, data: Service): Promise<void> {
        delete data.docId;
        await this.afs.collection(collection).doc(id).set(data, { merge: true });
    }

    /*
    * AUX Functions
    * */

    private async _convertDoc(document: Service) {
        if (document.user) await this._formatRef(document, 'user');
        if (document.date) this.convertToDate(document, 'date');
    }

    private async _formatRef(object: Service, key: string) {
        // @ts-ignore
        object[key] = await this.fireSvc.get(object[key]);
    }

    private convertToDate(object: Service, key: string) {
        object[key] = object[key].toDate();
    }
}
