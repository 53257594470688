import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { StatusBar, Style } from '@capacitor/status-bar';
import { LocalStorageProvider } from '@providers/local-storage/local-storage';
import { UsersService } from '@services/firebase/users/users.service';
import { User } from '@models/user.model';
import { WebNotificationsService } from '@services/firebase/web-notifications/web-notifications.service';
import { NotificationsProvider } from '@providers/notifications/notifications';
import * as LogRocket from 'logrocket';
import { register } from 'swiper/element/bundle';
import { environment } from '../environments/environment';
import { APP_VERSION } from '@constants/general.constants';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  private activateLogRocket: boolean = true;
  private isNative = this.platform.is('capacitor');
  private userId: string;
  private user: User;

  get isMobile() { return this.platform.is('mobile'); }
  get isTablet() { return this.platform.is('tablet'); }
  get isDesktop() { return this.platform.is('desktop'); }

  constructor (
      private platform: Platform,
      private usersSvc: UsersService,
      private localStorage: LocalStorageProvider,
      private webNotificationsSvc: WebNotificationsService,
      private notifications: NotificationsProvider,
  ) {
    this.initializeApp();
    console.log('platform', this.platform.platforms());
    // this.webNotificationsSvc.requestPermission();
  }

  public initializeApp() {
    if (this.activateLogRocket) LogRocket.init('bdjpve/app-home');
    this.getUser();
    if (this.isNative) this.notifications.initializeCapacitorPush();
    if (!this.isNative) this.notifications.initializeWebPush();
    if (this.isNative) {
      this.platform.ready().then(async () => {
        // iOS only
        window.addEventListener('statusTap', () => {
          console.info('statusbar tapped');
        });
        await StatusBar.setStyle({style: Style.Light});
      });
    }
    this._updatePushToken();
  }

  private async getUser() {
    this.userId = await this.localStorage.getUserId();
    if (this.userId) this.user = await this.usersSvc.getUser(this.userId, true);
    if (this.user) this.subscribeUser();
  }

  private async _updatePushToken() {
    const pushToken = await this.localStorage.getPushToken();
    if (this.userId && pushToken) { await this.usersSvc.updateUser(this.userId, {lastToken: pushToken} as User) }
  }

  private subscribeUser() {
    console.info('Subscribing user', this.user);
    LogRocket.identify(this.userId, {
      name: this.userId,
      email: this.user.email ? this.user.email : '',
      uid: this.userId,
      version: APP_VERSION,
      env: environment.production ? 'production' : 'development',
    });
  }
}
