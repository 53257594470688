import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';


@Component({
  selector: 'app-capacitor-scanner',
  templateUrl: './capacitor-scanner.component.html',
  styleUrls: ['./capacitor-scanner.component.scss'],
})
export class CapacitorScannerComponent implements OnInit, AfterViewInit {

  @Input() allowedFormats = [BarcodeFormat.QR_CODE];
  @Output() result = new EventEmitter();
  @ViewChild('scanner', { static: false })
  scanner: ZXingScannerComponent;
  hasPermission: boolean;
  qrResult: string;
  loading = {
    scanning: false,
    video: true,
  }
  flash = false
  isSupported = false;
  permissions: string;

  constructor(
  ) { 
  }
  
  ngOnInit(): void {
    BarcodeScanner.isSupported().then((result) => {
      this.isSupported = result.supported;
    });
  }

  ngAfterViewInit() {
    this.requestPermissions().then((answer: boolean) => {
      this.hasPermission = answer;
    });

    this.scanner.camerasNotFound.subscribe((devices: MediaDeviceInfo[]) => {
      console.error(
        'An error has occurred when trying to enumerate your video-stream-enabled devices.'
      );
    });
  }

  async requestPermissions(): Promise<boolean> {
    const { camera } = await BarcodeScanner.requestPermissions();
    this.permissions = camera;
    return camera === 'granted' || camera === 'limited';
  }

  async onCodeResult(result: string) {    
    if (result !== this.qrResult) {
      this.makeFlash()
      this.loading.scanning = true
      try {
        this.qrResult = result;
        this.result.emit(this.qrResult);
      } catch (error) {
        console.warn('[web-scanner] Error:', error)
      }
    } 
    this.loading.scanning = false;
  }

  videoStarted() {
    this.loading.video = false
  }

  makeFlash() {
    this.flash = true
    const canVibrate = window.navigator.vibrate as any
    if (canVibrate) window.navigator.vibrate(300)
    new Promise(resolve => setTimeout(resolve, 300)).then(() => {
      this.flash = false
    })
  }  

}
