export const environment = {
  production: true,
  mock: false,
  homepage: 'https://rembre.cl',
  baseBackend: 'https://us-central1-rembre-server.cloudfunctions.net',
  endingCallUrl: '-7cfo3sqhoq-uc.a.run.app',
  enableApiDebug: false,
  googleMapsApiKey: '',
  functionsRegion: 'us-central1',
  firebase: {
    apiKey: "AIzaSyDqS9d35htX3S96C-ASy0DukHhUIHvgawo",
    authDomain: "rembre-home-prod.firebaseapp.com",
    projectId: "rembre-home-prod",
    storageBucket: "rembre-home-prod.appspot.com",
    messagingSenderId: "827870143355",
    appId: "1:827870143355:web:a2e00d01ab6bbd783193de",
    measurementId: "G-W1NTGWQ6GM",
    vapidKey: "BHd2lkLclqK4UVd_uhCoiXaW6FcYyHI3BlIsdtOKAejFPGohNbjxjxbZtlG6Pq4o9hO77Ru-To8SMQNijPX-ZSg",
  },
  firebaseServer: {
    apiKey: "AIzaSyBfyUOy4wRlAM7cxXmRPBx7BN0gCWSHwyY",
    authDomain: "rembre-server-prod.firebaseapp.com",
    projectId: "rembre-server-prod",
    storageBucket: "rembre-server-prod.appspot.com",
    messagingSenderId: "345056430749",
    appId: "1:345056430749:web:e4dc31e07a5a79ea50a274",
    measurementId: "G-MVGPDCW7B4"
  },
  remoteConfig: {
    minimumFetchIntervalMillis: 3600000,
    fetchTimeoutMillis: 60000
  },
  mercadopago: {
    public_key_production: "APP_USR-1e0b3674-3e84-4083-9743-7aeaa2666c73",
    public_key_test: ""
  }
};
