import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IonSelect } from '@ionic/angular';


@Component({
  selector: 'app-evaluate-service',
  templateUrl: './evaluate-service.component.html',
  styleUrls: ['./evaluate-service.component.scss'],
})
export class EvaluateServiceComponent {
  @ViewChild(IonSelect) select: IonSelect;

  public score: any;

  @Output() getScore = new EventEmitter();

  constructor() {}

  public async openSelect() {
    await this.select.open()
  }

  public selected(event: any){
    this.score = parseInt(event);
    this.getScore.emit(this.score);
  }

}
