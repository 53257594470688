import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Button } from '@models/general.model';

@Component({
  selector: 'app-without-plan-card',
  templateUrl: './without-plan.component.html',
  styleUrls: ['./without-plan.component.scss'],
})
export class WithoutPlanComponent {
  @Input() icon = '';
  @Input() text = 'Aun no tienes asignado un plan para realizar retiros o tu plan ya está inactivo.';
  @Input() fill;
  @Input() button: Button = { show: false, text: '', icon: '' };

  @Output() onClick = new EventEmitter();

  public onClickButton() {
    this.onClick.emit();
  }
}
