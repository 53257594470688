import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { regions } from '@constants/regions.constants';

const validators = [Validators.required];

@Component({
  selector: 'app-add-address-modal',
  templateUrl: './add-address-modal.component.html',
  styleUrls: ['./add-address-modal.component.scss'],
})
export class AddAddressModalComponent implements OnInit, OnChanges {
  public form: FormGroup;
  @Input() title: string = 'Agregar';
  @Input() data: any;
  public formattedAddress: any;

  constructor(
      private modalCtrl: ModalController

  ) {
    this._createForm();
  }

  ngOnInit(): void {
    if (this.data){
      this.formattedAddress = this.data;
      this._setForm();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data.currentValue) {
      this.formattedAddress = this.data;
      this._setForm();
    }
  }

  public getAddress(address: any) {
    console.log(address);
    this.formattedAddress = address;
    this._setForm();
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm() {
    const data = { ...this.form.value, updatingDocId: this.data ? this.data.docId : null };
    return this.modalCtrl.dismiss(data, 'confirm');
  }

  private _createForm() {
    this.form = new FormGroup({
      name: new FormControl('', validators),
      street: new FormControl('', validators),
      detail: new FormControl(''),
      commune: new FormControl('', validators),
      city: new FormControl(''),
      region: new FormControl('', validators),
      status:  new FormControl('active'),
      position: new FormGroup({
        lat: new FormControl(''),
        lng: new FormControl(''),
      }),
      postalCode: new FormControl(''),
    });
  }

  private _setForm() {
    Object.entries(this.formattedAddress).forEach(([key, value]) => {
      const formControl = this.form.get(key);
      if (this._checkValidValue(formControl, value)) formControl.setValue(value);
      else if (this._checkValidObject(formControl, value)) {
        Object.entries(value).forEach(([subKey, subValue]) => {
          const formControlSub = formControl.get(subKey);
          if (this._checkValidValue(formControlSub, subValue)) formControlSub.setValue(subValue);
        });
      }
    });
  }

  private _checkValidValue(formControl: any, value: any) {
    return formControl && typeof value !== 'object' && (value !== null || value !== '');
  }

  private _checkValidObject(formControl: any, value: any) {
    return formControl && typeof value === 'object' && value !== null;
  }

  protected readonly regions = regions;
}
