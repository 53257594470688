import { Injectable } from '@angular/core';
import { Dialog } from '@capacitor/dialog';

@Injectable()
export class DialogProvider {

  constructor() {}

  public async show(title: string, message: string) {
    await Dialog.alert({ title, message});
  }

  public async showConfirm(title: string, message: string) {
    const { value } = await Dialog.confirm({ title, message});
    return value;
  }


  public async showPrompt(title: string, message: string){
    const { value, cancelled } = await Dialog.prompt({
      title: title,
      message: message,
      okButtonTitle: 'Si, quiero',
      cancelButtonTitle: 'Cerrar'
    });

    console.log('Value:', value);
    console.log('Cancelled:', cancelled);
    return { value, cancelled };
  };

}
