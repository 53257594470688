import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ServerServicesService } from '@services/firebase-server/services/services.service';
import { Service, Tender } from '@models/service.model';

@Component({
  selector: 'app-tenders-modal',
  templateUrl: './tenders-modal.component.html',
  styleUrls: ['./tenders-modal.component.scss'],
})
export class TendersModalComponent implements OnInit {
  public selectedTender: Tender;
  public serviceId: string;
  public service: Service;
  public loadingPage = true;

  constructor(
      private modalCtrl: ModalController,
      private serverServicesSvc: ServerServicesService,
  ) {}

  async ngOnInit() {
    await this.getService();
    this.loadingPage = false;
  }

  public convertAmount(amount: number) {
    let formattedValue: any = parseFloat(amount.toString().replace(/,/g, ''));
    formattedValue = formattedValue.toLocaleString('es-CL');
    formattedValue = '$' + formattedValue;
    return formattedValue;
  }

  public cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  public confirm(index: number) {
    this.selectedTender = this.service.tenders[index];
    return this.modalCtrl.dismiss(this.selectedTender, 'confirm');
  }

  private async getService() {
    this.service = await this.serverServicesSvc.getService(this.serviceId);
  }
}
