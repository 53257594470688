import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BrowserProvider } from '@providers/browser/browser';

@Component({
  selector: 'app-tac',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent {
  @Input() termsAndConditions?: boolean = false;
  @Output() onClick = new EventEmitter();

  constructor (
      private browserSvc: BrowserProvider,
  ) {
  }

  public async toggleTermsAndConditions() {
    this.termsAndConditions = !this.termsAndConditions;
    this.onClick.emit(this.termsAndConditions);
  }

  public async goToTermsAndConditions() {
    await this.browserSvc.openPopOver(environment.homepage);
  }
}
