import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailValidator } from '@utils/validators/email.validator';
import { UsersService } from '@services/firebase/users/users.service';
import { User } from '@models/user.model';
import { LocalStorageProvider } from '@providers/local-storage/local-storage';
import { Button } from '@models/general.model';
import { RUT_REGEX, TELEPHONE_REGEX } from '@constants/regex.constants';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit {
  @Input() fillData: boolean = false;
  @Input() buttonsOptions: boolean = true;
  @Input() onlyShow: boolean = false;
  @Input() firstButton: Button = { show: false, text: '' };
  @Input() secondButton: Button = { show: false, text: '' };

  public userForm: FormGroup;
  public loadingPage: boolean;
  public loading: boolean;
  public userId: string;
  public user: User;

  @Output() getData = new EventEmitter();
  @Output() dismissButton = new EventEmitter();

  constructor(
      private usersSvc: UsersService,
      private formBuilder: FormBuilder,
      private localStorage: LocalStorageProvider,
  ) {}

  get name() { return this.userForm.controls.name; }
  get lastName() { return this.userForm.controls.lastName; }
  get email() { return this.userForm.controls.email; }
  get rut() { return this.userForm.controls.rut; }
  get phone() { return this.userForm.controls.phone; }

  async ngOnInit () {
    this.loadingPage = true;
    if (this.onlyShow) this.loading = true;
    this.userId = await this.localStorage.getUserId();
    this.user = await this.usersSvc.getUser(this.userId);
    this._createForm();
    this.loadingPage = false;
  }

  public sendData() {
    this.getData.emit(this.userForm.value);
  }

  public clickOnDismissButton() {
    this.dismissButton.emit();
  }

  private _createForm() {
    this.userForm = this.formBuilder.group({
      name: [this._getValue('name'), [Validators.required]],
      lastName: [this._getValue('lastName'), [Validators.required]],
      email: [this._getValue('email'), [Validators.required, EmailValidator]],
      rut: [this._getValue('rut'), [Validators.required, Validators.pattern(RUT_REGEX)]],
      phone: [this._getValue('phone'), [Validators.required, Validators.pattern(TELEPHONE_REGEX)]],
    });
  }

  private _getValue(key) {
    return this.user && this.user[key] && this.fillData ? this.user[key] : '';
  }
}
