import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';

@Injectable()
export class BrowserProvider {

  constructor() {}

  public async openExternalUrl(url: string) {
    await Browser.open({ url });
  }

  public async openPopOver(url: string) {
    await Browser.open({ url, presentationStyle: 'popover' });
  }

  public async openFullscreen(url: string) {
    await Browser.open({ url, presentationStyle: 'fullscreen' });
  }

}
