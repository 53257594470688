import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-date-form',
  templateUrl: './date-form.component.html',
  styleUrls: ['./date-form.component.scss'],
})
export class DateFormComponent implements OnInit {
  public serviceDate: Date;
  public minDate: Date = new Date();

  @Output() getData = new EventEmitter();

  ngOnInit() {
    this.minDate.setDate(this.minDate.getDate() + 2);
  }

  public getDate(event) {
    this.getData.emit(this.serviceDate);
  }

  isWeekday = (dateString: string) => {
    const date = new Date(dateString);
    const utcDay = date.getUTCDay();
    /**
     * Date will be enabled if it is not
     * Sunday or Saturday
     */
    return utcDay !== 0 && utcDay !== 6;
  };
}
