import { Injectable } from '@angular/core';
import { FirebaseServerService } from '@services/firebase-server/firebase-server.service';
import firebase from 'firebase/compat';

const collection = 'products';

@Injectable({
  providedIn: 'root'
})
export class ServerProductsService {
  public db: firebase.firestore.Firestore;
  private products: any[] = [];

  constructor(
      private fireServerSvc: FirebaseServerService,
  ) {
    this.db = fireServerSvc.getDb();
  }

  public async getProduct(id: string) {
    const product = this.products.find(p => p.docId === id);
    if (product) return product;
    return (await this.db.collection(collection).doc(id).get()).data() as any;
  }

  public async getProducts(force = false): Promise<any> {
    if (force || !this.products.length) {
      await this.loadProducts();
    }
    return this.products;
  }

  private async loadProducts() {
    const ref = this.db.collection(collection).where('active', '==', true);
    const querySnapshot = await ref.get();
    this.products = querySnapshot.docs.map(doc => ({...doc.data() as any}));
  }
}
