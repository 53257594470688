import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rich-inner-text',
  templateUrl: './rich-inner-text.component.html',
  styleUrls: ['./rich-inner-text.component.scss'],
})
export class RichInnerTextComponent {
  @Input() richText: string;

}
