import { Injectable } from '@angular/core';
import { User } from '@models/user.model';
import { FirebaseServerService } from '@services/firebase-server/firebase-server.service';
import firebase from 'firebase/compat';

const collection = 'products';

@Injectable({
  providedIn: 'root'
})
export class LoyaltyPointsService {
  public db: firebase.firestore.Firestore;
  private points: number;
  private movements: any[];
  private totals = {
    accumulations: 0,
    redeems: 0,
  };

  constructor(
      private fireServerSvc: FirebaseServerService,
  ) {
    this.db = fireServerSvc.getDb();
  }

  public async getPoints(user: User, force = false) {
    if (force || !this.points) {
      const response = await this.fireServerSvc.callFunction('getLoyaltyPoints', {
        rut: user.rut || user.nationalId,
        email: user.email,
      });
      if (response.code && response.code === 'user-not-found') {
        console.error(response.message);
        this.points = -1;
      } else {
        this.points = response.points;
      }
    }
    return this.points;
  }

  public async getMovements(user: User, force = false) {
    if (force || !this.movements || !this.movements.length) {
      const response = await this.fireServerSvc.callFunction('getLoyaltyMovements', {
        rut: user.rut || user.nationalId,
        email: user.email,
      });
      if (response.code && response.code === 'user-not-found') {
        console.error(response.message);
        this.movements = [];
      } else {
        this.movements = response;
      }
    }
    return this.movements;
  }

  public async getAccumulatedByYear(user: User, selectedYear: number, force = false) {
    if (force || !this.movements || !this.movements.length) {
      const response = await this.fireServerSvc.callFunction('getLoyaltyTotals', {
        rut: user.rut || user.nationalId,
        email: user.email,
        year: selectedYear,
      });
      if (response.code && response.code === 'user-not-found') {
        console.error(response.message);
        this.totals.accumulations = -1;
        this.totals.redeems = -1;
      } else {
        this.totals.accumulations = response.totalAccumulations;
        this.totals.redeems = response.totalRedeems;
      }
    }
    return this.totals;
  }

  public async redeemPoints(user: User, points: number) {
    const response = await this.fireServerSvc.callFunction('redeemLoyaltyPoints', {
      rut: user.rut || user.nationalId,
      email: user.email,
      points,
    });
    if (response.code && response.code === 'user-not-found') {
      console.error(response.message);
      return false;
    }
    return true;
  }
}
