import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-buttons-menu',
  templateUrl: './buttons-menu.component.html',
  styleUrls: ['./buttons-menu.component.scss'],
})
export class ButtonsMenuComponent {
  @Input() firstIcon;
  @Input() secondIcon;
  @Input() thirdIcon;
  @Input() fourthIcon;

  @Output() firstIconClick = new EventEmitter();
  @Output() secondIconClick = new EventEmitter();
  @Output() thirdIconClick = new EventEmitter();
  @Output() fourthIconClick = new EventEmitter();

  public onFirstClick() {
    this.firstIconClick.emit();
  }

  public onSecondClick() {
    this.secondIconClick.emit();
  }

  public onThirdClick() {
    this.thirdIconClick.emit();
  }

  public onFourthClick() {
    this.fourthIconClick.emit()
  }
}
