import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton-card',
  templateUrl: './skeleton-card.component.html',
  styleUrls: ['./skeleton-card.component.scss'],
})
export class SkeletonCardComponent {
  @Input() show: boolean;
  @Input() quantity: number;
  @Input() bigCard: boolean;

  counter(i: number) {
    return new Array(i);
  }
}
