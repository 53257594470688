import { Tenants } from '@models/client.model';
import { User } from '@models/user.model';

interface IBaseMock {
  [name: string]: IResponseMockLogin | IErrorMock;
}

export interface IResponseMockLogin {
  status: number;
  response: LoginResponse;
}

export interface LoginResponse {
  token: string;
  user: User;
  tenants: Tenants[];
}

interface IErrorMock {
  status: number;
  error: {
    message: string;
  };
}

export const loginMocks: IBaseMock = {
  OK: {
    status: 200,
    response: {
      token: 'de1b89dd-b230-4130-8235-ae557fc0b238',
      user: {
        name: 'Pedro Pablo',
        lastName: 'Perez Pereira',
        rut: '12.345.678-9',
        phone: '+56912345678',
        email: 'example@rayoslab.com',
      } as User,
      tenants: [
        {
          id: 1,
          name: 'Rembre',
          logo: 'https://picsum.photos/200',
          links: [
            {
              title: 'Guía de Reciclaje',
              url: 'https://rembre.cl'
            }
          ]
        },
        {
          id: 2,
          name: 'Rayoslab',
          logo: 'https://picsum.photos/300',
          links: [
            {
              title: 'Términos y Condiciones',
              url: 'https://google.com'
            },
            {
              title: 'Guía de Reciclaje',
              url: 'https://google.com'
            }
          ]
        }
      ]
    }
  },
  BAD_REQUEST_NOT_VALID: {
    status: 400,
    error: {
      message: 'PARAM_NOT_VALID'
    },
  },
  CONFLICT_VERSION_UNSUPPORTED: {
    status: 409,
    error: {
      message: 'VERSION_UNSUPPORTED'
    },
  },
  INTERNAL_SERVER_ERROR_GENERIC: {
    status: 500,
    error: {
      message: 'GENERIC_ERROR'
    },
  }
};
