import { Component, Input, OnInit } from '@angular/core';
import { ConfigService } from '@services/firebase/config/config.service';
import { UtilsService } from '@services/utils/utils.service';

@Component({
  selector: 'app-lateral-banner',
  templateUrl: './lateral-banner.component.html',
  styleUrls: ['./lateral-banner.component.scss'],
})
export class LateralBannerComponent implements OnInit {

  @Input() image: 'primary' | 'secondary';
  public url: string;
  public button: any;

  get showButton() { return this.button?.text && this.button?.link }

  constructor(
    private configService: ConfigService,
    private utils: UtilsService,
  ) { }

  ngOnInit() {
    this.configService.getConfig('lateral-banner').then((config) => {
      this.url = config[this.image].url;
      this.button = {
        text: config[this.image].buttonText,
        link: config[this.image].buttonLink,
        color: config[this.image].buttonColor,
      }
    });
  }

  getScssUrl(url: string) {
    return this.utils.getScssUrl(url)
  }

  goTo(link: string) {
    window.open(link, '_blank');
  }

}
