import { Component, Input, OnInit } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  @Input() show: boolean;

  constructor(
      private loadingCtrl: LoadingController,
  ) {}

  ngOnInit () {
    if (this.show) this.showLoading();
  }

  public async showLoading() {
    const loading = await this.loadingCtrl.create({
      message: 'Cargando...',
      duration: 1000,
    });

    await loading.present();
  }
}
