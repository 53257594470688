import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-hour-form',
  templateUrl: './hour-form.component.html',
  styleUrls: ['./hour-form.component.scss'],
})
export class HourFormComponent {
  public isPMHour: boolean;

  @Output() getData = new EventEmitter();
  @Output() dismissButton = new EventEmitter();

  public getHour(value) {
    this.isPMHour = value;
    this.getData.emit(this.isPMHour);
  }
}
