import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Plan } from '@models/plan.model';

const collection: string = 'plans';

@Injectable()
export class PlansService {
    constructor (
        private afs: AngularFirestore,
    ) {}

    public async getPlan(id: string): Promise<Plan> {
        const doc = await this.afs.collection(collection).doc(id).ref.get();
        if (!doc.exists) { console.error('No such document!'); }
        return {docId: doc.id, ...doc.data() as Plan};
    }

    public async getPlans(): Promise<Plan[]> {
        const querySnapshot = await this.afs.collection(collection).ref
            .where('active', '==', true).get();
        return querySnapshot.docs.map(doc => ({...doc.data() as Plan, docId: doc.id}));
    }
}
