import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { CustomForm } from '@models/custom-form.model';

const collection: string = 'group-user';
const subCollection: string = 'properties';

@Injectable()
export class GroupUserService {
    constructor (
        private afs: AngularFirestore,
    ) {}

    public async get(): Promise<any[]> {
        const querySnapshot = await this.afs.collection(collection).ref
            .where('active', '==', true).get();
        return querySnapshot.docs.map(doc => ({...doc.data() as any, docId: doc.id}));
    }

    public async getProperties(group: string): Promise<CustomForm[]> {
        const querySnapshot = await this.afs.collection(collection).doc(group).collection(subCollection).ref.get();
        const docs = querySnapshot.docs.map(doc => ({...doc.data() as CustomForm, docId: doc.id}));
        this.getDocsOptions(docs);
        return docs;
    }

    private getDocsOptions(docs: CustomForm[]) {
        docs.forEach((doc) => {
            if (doc.optionsType === 'collection') {
                this.getOptions(doc.collectionOptions).then((options) => doc.options = options);
            }
        });
    }

    private async getOptions(collectionOptions: string) {
        const querySnapshot = await this.afs.collection(collectionOptions).ref.get();
        return querySnapshot.docs.map(doc => ({...doc.data() as any, docId: doc.id}));
    }

}
