import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-fancy-toggle',
  templateUrl: './fancy-toggle.component.html',
  styleUrls: ['./fancy-toggle.component.scss'],
})
export class FancyToggleComponent implements OnInit {
  public initialValue = false;

  @Output() toggleValue = new EventEmitter();

  ngOnInit() {
    this.toggleValue.emit(this.initialValue);
  }

  public toggle() {
    this.initialValue = !this.initialValue;
    this.toggleValue.emit(this.initialValue);
  }
}
