import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaymentsRequest, PreferenceResponse } from '@models/payments.model';
import { paymentUrl, paymentUrlV2 } from '@constants/urls.constants';

const localUrl = 'http://127.0.0.1:5001/rembre-server-dev/us-central1/payments-createPreference';

@Injectable()
export class PaymentsService {
    private useLocalUrl: boolean = false;

    constructor (
        private http: HttpClient,
    ) {}

    public async createPreference(body: PaymentsRequest) {
        const url = this.useLocalUrl ? localUrl : paymentUrl;
        try {
            return await this.http.post(url, body).toPromise() as PreferenceResponse;
        } catch (error) {
            console.error('Error createPreference()', error);
            return error;
        }
    }
}
