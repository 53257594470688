import { Injectable, NgZone } from "@angular/core";
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import '@firebase/messaging';
import { Router } from "@angular/router";
import { getMessaging, onMessage } from "firebase/messaging";
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { LocalStorageProvider } from "@providers/local-storage/local-storage";
import { WebNotificationsService } from "@services/firebase/web-notifications/web-notifications.service";

@Injectable({
  providedIn: 'root'
})
export class NotificationsProvider {
  constructor (
    private router: Router,
    private localStorage: LocalStorageProvider,
    private ngZone: NgZone,
    private headerNotificationsSvc: WebNotificationsService
  ) {}

  public initializeWebPush() {
      // Foreground web push listener
      const messaging = getMessaging(firebase.app());
      onMessage(messaging, (payload) => {
          console.info('WebPush received in foreground', payload);
          this.handleForegroundNotification(payload.data);
      });

      // Background web push tap listener
      const channel = new BroadcastChannel('webpush-channel');
      channel.onmessage = (event) => {
          console.info('WebPush received in background:', event.data);
          this.handleTappedNotification(event.data);
      };
  }

  public initializeCapacitorPush() {
      const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
      if (isPushNotificationsAvailable) {
          console.info('PushNotifications plugin is available');
          this.registerCapacitorPush();
          this.addCapacitorPushListeners();
      } else {
          console.warn('PushNotifications plugin is not available');
      }
  }

  private registerCapacitorPush() {
      PushNotifications.checkPermissions().then(permission => {
        console.log('Push check permission: ', permission);
        PushNotifications.requestPermissions().then(permission => {
          console.log('Push permission: ', permission);
          if (permission.receive === 'granted') {
            PushNotifications.register();
          }
          else {
            // If permission is not granted
          }
        });
      });
  }

  private addCapacitorPushListeners() {
      PushNotifications.addListener('registration', (token) => {
          console.log('Push registration success, token: ' + token.value)
          this.localStorage.setPushToken(token.value);
      });

      PushNotifications.addListener('registrationError', (err)=> {
          console.error('Push registration error:', err);
      });

      PushNotifications.addListener('pushNotificationReceived', (notifications)=> {
          console.info('CapacitorPush received in foreground', notifications);
          this.handleForegroundNotification(notifications.data);
      });

      PushNotifications.addListener('pushNotificationActionPerformed', (action) => {
          console.info('CapacitorPush receivedactionPerformed', action.notification.data);
          this.handleTappedNotification(action.notification.data);
      });
  }

  private handleTappedNotification(data: any) {
      if (data?.action === 'INTERNAL_REDIRECT') {
        this.ngZone.run(async () => {
          switch (data.route) {
              case 'chat':
                  this.router.navigate(['enrolled', 'tabs', 'chat']);
                  break;
              case 'service':
                  this.router.navigate(['enrolled', 'tabs', 'withdrawals']);
                  break;
              default:
                  break;
          }
        });
      }
  }

  private handleForegroundNotification(data: any) {
    this.headerNotificationsSvc.updateNotifications();
  }

}
