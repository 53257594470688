import { Injectable } from '@angular/core';
import { Service, TO_PERFORM_STATUS } from '@models/service.model';
import { FirebaseServerService } from '@services/firebase-server/firebase-server.service';
import firebase from 'firebase/compat';
import { FirebaseService } from '@services/firebase/firebase.service';
import { Observable } from 'rxjs';

const collection: string = 'services';

@Injectable({
  providedIn: 'root'
})
export class ServerServicesService {
  public db: firebase.firestore.Firestore;

  constructor(
      private fireSvc: FirebaseService,
      private fireServerSvc: FirebaseServerService,
  ) {
    this.db = fireServerSvc.getDb();
  }

  public async getService(id: string): Promise<Service> {
    const doc = await this.db.collection(collection).doc(id).get();
    if (!doc.exists) { console.error('No such document!'); }
    const document = { docId: doc.id, ...doc.data() as Service };
    await this.convertDoc(document);
    return document;
  }

  public async getServiceByUser(userId: string): Promise<Service[]> {
    const querySnapshot = await this.db.collection(collection)
        .where('user.uuid', '==', userId)
        .get();
    return querySnapshot.docs.map(doc => ({...doc.data() as Service, docId: doc.id}));
  }

  public async getLastServiceByUser(userId: string): Promise<Service> {
    const querySnapshot = await this.db.collection(collection)
        .where('user.uuid', '==', userId)
        .orderBy('date', 'desc')
        .limit(1)
        .get();
    const services = querySnapshot.docs.map(doc => ({...doc.data() as Service, docId: doc.id}));
    return services[0];
  }

  public async getPlanServiceByUser(userId: string): Promise<Service[]> {
    const querySnapshot = await this.db.collection(collection)
        .where('user.uuid', '==', userId)
        .where('punctualWithdrawal', '==', false)
        .orderBy('date', 'desc')
        .get();
    return querySnapshot.docs.map(doc => ({...doc.data() as Service, docId: doc.id}));
  }

  public async getServiceToPerformByUser(userId: string): Promise<Service[]> {
    const querySnapshot = await this.db.collection(collection)
        .where('user.uuid', '==', userId)
        .where('status', 'in', TO_PERFORM_STATUS)
        .get();
    return querySnapshot.docs.map(doc => ({...doc.data() as Service, docId: doc.id}));
  }

  public getPuntualServiceByUserObs(userId: string): Observable<Service[]> {
    const query = this.db.collection(collection)
            .where('punctualWithdrawal', '==', true)
            .where('user.uuid', '==', userId)
            .orderBy('date', 'asc');

    return new Observable<Service[]>((observer) => {
      const unsubscribe = query.onSnapshot(querySnapshot => {
        console.log(`Received query snapshot of size ${querySnapshot.size}`);
        const documents = querySnapshot.docs.map(doc => ({...doc.data() as Service, docId: doc.id}));
        return observer.next(documents);
      }, err => {
        console.log(`Encountered error: ${err}`);
      });
      return () => unsubscribe();
    });
  }

  public async getServiceBySubscription(subId: string): Promise<Service[]> {
    console.info(`Searching services for ${ subId }`)
    const querySnapshot = await this.db.collection(collection)
        .where('punctualWithdrawal', '==', false)
        .where('subscriptionId', '==', subId).get();
    console.info(querySnapshot.size);
    return querySnapshot.docs.map(doc => ({...doc.data() as Service, docId: doc.id}));
  }

  public async createService(id: string, data: Service): Promise<any> {
    if (data.user) await this.formatRef(data, 'user');
    const cleanData = this.fireServerSvc.cleanDocRef(data);
    if (cleanData.user?.addresses) delete cleanData['user']['addresses'];
    return await this.db.collection(collection).doc(id).set(cleanData, { merge: true });
  }

  /*
  * AUX Functions
  * */

  private async convertDoc(document: Service) {
    if (document.date) this.convertToDate(document, 'date');
  }

  private async formatRef(object: Service, key: string) {
    // @ts-ignore
    object[key] = await this.fireSvc.get(object[key]);
  }

  private convertToDate(object: Service, key: string) {
    object[key] = object[key].toDate();
  }

}
