import { Injectable } from '@angular/core';
import { FirebaseServerService } from '@services/firebase-server/firebase-server.service';
import firebase from 'firebase/compat';

const collection = 'config';

@Injectable({
  providedIn: 'root'
})
export class ServerConfigService {
  public db: firebase.firestore.Firestore;

  constructor(
      private fireServerSvc: FirebaseServerService,
  ) {
    this.db = fireServerSvc.getDb();
  }

  public async getConfig(id: string): Promise<any> {
    const doc = await this.db.collection(collection).doc(id).get();
    if (!doc.exists) { console.error('No such document!'); }
    return doc.data() as any;
  }
}
