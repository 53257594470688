import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersService } from '@services/firebase/users/users.service';
import { FirebaseService } from '@services/firebase/firebase.service';
import { RemoteConfigService } from '@services/firebase/remote-config.service';
import { AddressesService } from '@services/firebase/addresses/addresses.service';
import { AuthService } from '@services/firebase/auth/auth.service';
import { AuthFireService } from '@services/firebase/auth/auth-fire.service';
import { ChatsService } from '@services/firebase/chats/chats.service';
import { ConstantsFirebaseService } from '@services/firebase/constants-firebase/constants-firebase.service';
import { PlansService } from '@services/firebase/plans/plans.service';
import { ServicesService } from '@services/firebase/services/services.service';
import { SubscriptionsService } from '@services/firebase/subscriptions/subscriptions.service';
import { WebNotificationsService } from '@services/firebase/web-notifications/web-notifications.service';
import { UtilsService } from '@services/utils/utils.service';
import { PaymentsService } from '@services/external-services/payments/payments.service';
import { FirebaseStorageService } from '@services/firebase/firebase-storage.service';
import { ServerServicesService } from '@services/firebase-server/services/services.service';
import { FirebaseServerService } from '@services/firebase-server/firebase-server.service';
import { ServerConfigService } from '@services/firebase-server/config/services.service';
import { ConfigService } from '@services/firebase/config/config.service';
import { ServerMaterialsService } from '@services/firebase-server/materials/materials.service';
import { NewsService } from './firebase/news/news.service';
import { PhotoService } from './firebase/photo/photo.service';
import { PlansCustomFieldsService } from '@services/firebase/plans-custom-fields/plans-custom-fields.service';
import { GroupUserService } from '@services/firebase/group-user/group-user.service';
import { QrCodesService } from './firebase/qr-codes/qr-codes.service';
import { ServerProductsService } from './firebase-server/products/products.service';
import { RouteFilterDayService } from '@services/firebase-server/route-filter-day/route-filter-day.service';
import { LoyaltyPointsService } from './firebase-server/loyalty-points/loyalty-points.service';
import { ServerRedeemsService } from './firebase-server/redeems/redeems.service';
import { ExtraContentService } from './firebase/extra-content/extra-content.service';

const GLOBAL_SERVICES = [
    FirebaseService,
    FirebaseStorageService,
    RemoteConfigService,
    UtilsService,
];

const EXTERNAL_SERVICES = [
    PaymentsService,
];

const SERVICES = [
    AddressesService,
    AuthService,
    AuthFireService,
    ChatsService,
    ConfigService,
    ConstantsFirebaseService,
    GroupUserService,
    PlansService,
    PlansCustomFieldsService,
    NewsService,
    ExtraContentService,
    PhotoService,
    ServicesService,
    SubscriptionsService,
    UsersService,
    WebNotificationsService,
    QrCodesService,
];

const FIREBASE_SERVER_SERVICES = [
    FirebaseServerService,
    ServerServicesService,
    ServerConfigService,
    ServerMaterialsService,
    ServerProductsService,
    ServerRedeemsService,
    RouteFilterDayService,
    LoyaltyPointsService,
];


@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
      ...FIREBASE_SERVER_SERVICES,
      ...EXTERNAL_SERVICES,
      ...GLOBAL_SERVICES,
      ...SERVICES,
  ],
})
export class ServicesModule {
  static forRoot(): ModuleWithProviders<ServicesModule> {
    return {
      ngModule: ServicesModule,
      providers: [
          ...FIREBASE_SERVER_SERVICES,
          ...EXTERNAL_SERVICES,
          ...GLOBAL_SERVICES,
          ...SERVICES,
      ],
    };
  }
}
