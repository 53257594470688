import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserProvider } from '@providers/browser/browser';
import { CameraProvider } from '@providers/camera/camera';
import { DeviceProvider } from '@providers/device-info/device';
import { DialogProvider } from '@providers/dialog/dialog';
import { HttpInterceptorProvider } from '@providers/http-interceptor/http-interceptor';
import { LocalStorageProvider } from '@providers/local-storage/local-storage';
import { ModalProvider } from '@providers/modal/modal';
import { ToastProvider } from '@providers/toast/toast';
import { NotificationsProvider } from '@providers/notifications/notifications';
import { GeolocationProvider } from '@providers/geolocation/geolocation';

const PROVIDERS = [
    BrowserProvider,
    CameraProvider,
    DeviceProvider,
    DialogProvider,
    GeolocationProvider,
    HttpInterceptorProvider,
    LocalStorageProvider,
    ModalProvider,
    NotificationsProvider,
    ToastProvider,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    ...PROVIDERS,
  ],
})
export class ProvidersModule {
  static forRoot(): ModuleWithProviders<ProvidersModule> {
    return {
      ngModule: ProvidersModule,
      providers: [
        ...PROVIDERS,
      ],
    };
  }
}
