import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';

@Injectable()
export class DeviceProvider {

  constructor() {}

  public logDeviceInfo = async () => {
    const info = await Device.getInfo();
  };

  public logBatteryInfo = async () => {
    const info = await Device.getBatteryInfo();
  };

}
