import { Component, Input, OnInit } from '@angular/core';
import { Chat } from '@models/chat.model';
import { UtilsService } from '@services/utils/utils.service';
import { ModalController } from '@ionic/angular';
import { GenericModalComponent } from '@components/modals/generic-modal/generic-modal.component';


@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.scss'],
})
export class ChatBoxComponent implements OnInit {
  @Input() chat: Chat;
  @Input() userId;

  constructor(
    private utils: UtilsService,
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {}

  getScssUrl(url: string) {
    return this.utils.getScssUrl(url)
  }

  showImage(url: string) {
    this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'auto-height',
      componentProps: {
        image: url,
        showButtons: false,
        icon: undefined,
      }
    }).then(modal => {
      modal.present();
    })
  }

}
