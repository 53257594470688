import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { User } from '@models/user.model';

const collection: string = 'users';

@Injectable()
export class UsersService {

    private user: User;

    constructor (
        private afs: AngularFirestore,
    ) {}

    public async getUser(id: string, force = false): Promise<User> {
        if (this.user?.name && this.user?.email && this.user.docId === id && !force) return this.user;

        await this.afs.firestore.waitForPendingWrites()
        const snap = await this.afs.firestore.doc(`${collection}/${id}`).get({ source: 'server' });
        if (!snap.exists) { console.error('No such document!'); }
        this.user = { docId: snap.id, ...snap.data() } as User;
        return this.user;
    }

    public async createUser(id: string, data: any): Promise<void> {
        await this.afs.collection(collection).doc(id).set(data);
    }

    public async updateUser(id: string, data: any): Promise<User> {
        delete data.docId;
        await this.afs.collection(collection).doc(id).set(data, { merge: true });
        return await this.getUser(id, true);
    }
}
