import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ExtraContentInterface } from '@models/extra-content.model';
import { BrowserProvider } from '@providers/browser/browser';
import { UtilsService } from '@services/utils/utils.service';


@Component({
  selector: 'app-extra-content-card',
  templateUrl: './extra-content-card.component.html',
  styleUrls: ['./extra-content-card.component.scss'],
})
export class ExtraContentCardComponent {
  @Input() extraContent: ExtraContentInterface;
  @Input() expanded: boolean = false;
  @Input() showBackground: boolean = false;

  get shouldShowBackground() { return this.showBackground && this.extraContent.backgroundUrl }

  constructor(
    private router: Router,
    private browser: BrowserProvider,
    private utils: UtilsService,
  ) {

  }

  public async openLink() {
    if (this.extraContent.internalRedirection) {
      await this.router.navigate(['enrolled', 'tabs', 'extra-content', 'detail'], { queryParams: { id: this.extraContent.docId } });
      return;
    }
    await this.browser.openFullscreen(this.extraContent.url);
  }

  public async download() {
    await this.browser.openExternalUrl(this.extraContent.downloadUrl);
  }

  getScssUrl(url: string) {
    return this.utils.getScssUrl(url)
  }

}
