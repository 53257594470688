import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Button } from '@models/general.model';
import { User } from '@models/user.model';
import { Address } from '@models/address.model';
import { LocalStorageProvider } from '@providers/local-storage/local-storage';
import { UsersService } from '@services/firebase/users/users.service';
import { regions } from '@constants/regions.constants';
import { ModalController } from '@ionic/angular';
import { AddAddressModalComponent } from '@components/modals/add-address-modal/add-address-modal.component';
import { AddressesService } from '@services/firebase/addresses/addresses.service';
import { FirebaseService } from '@services/firebase/firebase.service';
import { SubscriptionsService } from '@services/firebase/subscriptions/subscriptions.service';
import { UtilsService } from '@services/utils/utils.service';
import { GenericModalComponent } from '@components/modals/generic-modal/generic-modal.component';
import { Service } from '@models/service.model';
import { ServerServicesService } from '@services/firebase-server/services/services.service';

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss'],
})
export class AddressesComponent implements OnInit {
  @Input() fillData: boolean = false;
  @Input() buttonsOptions: boolean = true;
  @Input() addNew: boolean = true;
  @Input() onlyShow: boolean = true;
  @Input() delete: boolean = false;
  @Input() disableDelete: boolean = true;
  @Input() edit: boolean = true;
  @Input() removeAddressWithSubs: boolean = true;
  @Input() firstButton: Button = { show: false, text: '' };
  @Input() secondButton: Button = { show: false, text: '' };

  public loadingPage: boolean;
  public loading: boolean;
  public userId: string;
  public user: User;
  public clientAddresses: Address[] = [];
  public selectedAddress: Address;
  public services: Service[];
  protected readonly regions = regions;

  @Output() getData = new EventEmitter();
  @Output() dismissButton = new EventEmitter();
  @Output() deleteAddressEvent = new EventEmitter();

  constructor (
      private utilsSvc: UtilsService,
      private usersSvc: UsersService,
      private fireSvc: FirebaseService,
      private modalCtrl: ModalController,
      private subsSvc: SubscriptionsService,
      private servicesSvc: ServerServicesService,
      private addressesSvc: AddressesService,
      private localStorage: LocalStorageProvider,
  ) {}

  async ngOnInit() {
    this.loadingPage = true;
    this.userId = await this.localStorage.getUserId();
    this.user = await this.usersSvc.getUser(this.userId);
    if (this.onlyShow) this.loading = true;
    if (this.fillData) await this._getAddresses();
    this.services = await this.servicesSvc.getServiceToPerformByUser(this.userId);
    this.loadingPage = false;
  }

  public async addAddress() {
    await this.openModal()
  }

  async openModal() {
    const modal = await this.modalCtrl.create({ component: AddAddressModalComponent });
    await modal.present();
    const { data, role } = await modal.onWillDismiss();
    if (role === 'confirm') {
      this.clientAddresses.push(data)
      const addressDocId = await this._saveAddress(data);
      await this.updateUserInfo(addressDocId);
    }
  }

  public toggleAddress(index: number){
    this.clientAddresses[index].show = !this.clientAddresses[index].show;
  };

  public selectAddress(index: number) {
    for(let i = 0; i < this.clientAddresses.length; i++) {
      if (this.clientAddresses[i].selected) this.clientAddresses[i].selected = false;
    }
    this.clientAddresses[index].selected = true;
    this.selectedAddress = this.clientAddresses[index];
    this.sendData();
  }

  public canEditOrDelete(index: number) {
    if (this.services && this.services.length)
      return this.services.find(service => service.address.docId === this.clientAddresses[index].docId);
    else
      return false;
  }

  public sendData() {
    this.getData.emit(this.selectedAddress);
  }

  public async deleteAddress(index: number) {
    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'auto-height',
      componentProps: {
        title: 'Eliminación de dirección',
        icon: 'address',
        message: '¿Realmente desea eliminar la dirección?',
        primaryButtonText: 'Confirmar',
        primaryButtonColor: 'danger',
        secondaryButtonText: 'Cancelar',
      }
    });
    await modal.present();
    const {data, role} = await modal.onWillDismiss();
    if (role === 'confirm') {
      await this.addressesSvc.updateAddress(this.clientAddresses[index].docId, {status: 'deleted'} as Address);
      this.ngOnInit();
    }
  }

  public async editAddress(index: number) {
    let modal = await this.modalCtrl.create({
      component: AddAddressModalComponent ,
      componentProps: {
        title: 'Editar',
        data: this.clientAddresses[index],
      }
    });
    await modal.present();
    const { data, role } = await modal.onWillDismiss();
    if (role === 'confirm') {
      this.clientAddresses.push(data)
      if (data.updatingDocId) {
        await this._updateAddress(data.updatingDocId, data);
        this.ngOnInit();
      } else {
        const addressDocId = await this._saveAddress(data);
        await this.updateUserInfo(addressDocId);
      }
    }
  }

  public clickOnDismissButton() {
    this.dismissButton.emit();
  }

  private async _getAddresses() {
    this.clientAddresses = await this.addressesSvc.getAddressesByUser(this.userId);
    if (this.removeAddressWithSubs) await this._removeAddressWithSubs();
    if (this.disableDelete) await this._disableAddressWithSubs();
  }

  private async _saveAddress(data: Address) {
    const userRef = this.fireSvc.createRef('users', this.userId);
    const addressData = {...data, user: userRef};
    try {
      return await this.addressesSvc.createAddress(addressData);
    } catch (err) {
      return err;
    }
  }

  private async _updateAddress(docId: string, data: Address) {
    await this.addressesSvc.updateAddress(docId, data);
  }

  private async updateUserInfo(addressDocId: string) {
    const addressRef = this.fireSvc.createRef('addresses', addressDocId);
    this.user.addresses.push(addressRef);
    await this.usersSvc.updateUser(this.userId, this.user);
  }

  private async _removeAddressWithSubs() {
    for (const address of this.clientAddresses) {
      const sub = await this.subsSvc.getSubscriptionsByAddress(address.docId);
      if (sub.length) this.clientAddresses = this.utilsSvc.remove(this.clientAddresses, 'docId', address.docId);
    }
  }

  private async _disableAddressWithSubs() {
    for (const address of this.clientAddresses) {
      const sub = await this.subsSvc.getSubscriptionsByAddress(address.docId);
      if (sub.length) address.hasSub = true;
    }
  }
}
