import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Address } from '@models/address.model';

const collection: string = 'addresses';

@Injectable({
    providedIn: 'root'
})
export class AddressesService {
    constructor (
        private afs: AngularFirestore,
    ) {}

    public createRef(col: string, docId: string) {
        return this.afs.collection(col).doc(docId).ref;
    }

    public async getAddress(id: string): Promise<Address> {
        const doc = await this.afs.collection(collection).doc(id).ref.get();
        if (!doc.exists) { console.error('No such document!'); }
        return { docId: doc.id, ...doc.data() as Address };
    }

    public async getAddressesByUser(userId): Promise<Address[]> {
        const userRef = this.createRef('users', userId);
        const querySnapshot = await this.afs.collection(collection).ref
            .where('user', '==', userRef)
            .where('status', '==', 'active')
            .get();
        return querySnapshot.docs.map(doc => ({...doc.data() as Address, docId: doc.id}));
    }

    public async updateAddress(id: string, data: Address): Promise<any> {
        return await this.afs.collection(collection).doc(id).set(data, { merge: true });
    }

    public async createAddress(data: Address): Promise<any> {
        return await this.afs.collection(collection).add(data)
            .then((docRef) => { return docRef.id; })
            .catch((error) => { return error; });
    }

}
