import { Pipe, PipeTransform } from '@angular/core';
import LinkifyIt from 'linkify-it';


@Pipe({ name: 'linkifyDirective' })
export class LinkifyDirectivePipe implements PipeTransform {
    transform(value: string): string {
        const linkify = LinkifyIt(); 
        linkify
          .add('git:', 'http:')           // Add `git:` protocol as "alias"
          .add('ftp:', null)              // Disable `ftp:` protocol
          .set({ fuzzyIP: true });        // Enable IPs in fuzzy links (without schema)

        // Implement your logic to convert text links to HTML links
        let finalValue = value
        if (linkify.test(finalValue)) {
            for (const match of linkify.match(finalValue).reverse()) { // en reversa para q no cambien los index
                const link = `<a class="custom-chat-link" href="${match.url}">${match.text}</a>`
                const head = finalValue.slice(0, match.index)
                const tail = finalValue.slice(match.lastIndex)
                finalValue = [head, link, tail].join('')
            }
            return finalValue
        }
        return value;
    }
}
