import { Address } from '@models/address.model';
import { WorkShift } from '@models/work-shift.model';
import { DocumentReference } from '@angular/fire/compat/firestore';
import { User } from '@models/user.model';
import { Subscription } from '@models/subscription.model';

type ServiceStatus =
    'created'
    | 'pending'
    | 'rescheduled'
    | 'voided'
    | 'accepted'
    | 'selected'
    | 'ready'
    | 'done'
    | 'cancel'
    | 'failed'
    | 'confirmed'
    | 'evaluate'
    | 'completed';

type PunctualServiceStatus =
    | 'payment-pending'
    | 'payable'
    | 'paid';

export const PERFORMED_STATUS = ['done', 'evaluate', 'completed', 'failed', 'voided', 'expired'];
export const TO_PERFORM_STATUS = ['created', 'pending', 'accepted', 'payable', 'paid', 'ready', 'confirmed', 'rescheduled'];

export const SERVICE_STATUS = {
  created: 'Agendado',
  pending: 'Pendiente',
  accepted: 'Aceptado',
  payable: 'Por pagar',
  paid: 'Pagado',
  ready: 'Por realizar',
  confirmed: 'Confirmado',
  rescheduled: 'Re-agendado',
  evaluate: 'A evaluar',
  done: 'Realizado',
  completed: 'Completado',
  failed: 'Fallido',
  voided: 'Anulada',
  expired: 'No Realizado',
  tentative: 'Tentativo', // LOCAL
};

export const SERVICE_CHIP = {
  created: 'secondary',
  pending: 'warning',
  ready: 'tertiary',
  accepted: 'primary',
  confirmed: 'primary',
  rescheduled: 'secondary',
  done: 'success',
  completed: 'success',
  failed: 'danger',
  voided: 'danger',
  expired: 'warning',
  tentative: 'warning',
};


export interface Resource {
  type: 'img' | 'document';
  url: string;
  description: string;
  tag: string;
}

export interface Service {
  createdAt: Date;
  docId: string;
  id: string;
  subscription: Subscription | DocumentReference;
  subscriptionId: string;
  user: User | DocumentReference;
  operator: User | DocumentReference | any;
  score: number;
  address: Address;
  date: Date | any;
  firestorePaymentId: string;
  diffFirestorePaymentId: string;
  workShift: WorkShift;
  punctualWithdrawal: boolean;
  amount: number;
  resources: Resource[];
  status: ServiceStatus | PunctualServiceStatus;
  materials: number[];
  tenders: any;
  paymentData: any;
  qualify: {
    user: number;
    recycler: number;
  };
}

export interface Tender {
  amount: number;
  operatorId: string;
  name: string;
  operator: User;
}

