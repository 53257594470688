import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { EnrolledGuard } from './guards/enrolled.guard';

const routes: Routes = [
  { path: 'auth', canActivate: [AuthGuard],  loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)},
  { path: 'enrolled', canActivate: [EnrolledGuard], loadChildren: () => import('./modules/enrolled/enrolled.module').then(m => m.EnrolledModule)},
  { path: '', redirectTo: 'enrolled', pathMatch: 'full' },
  { path: '**', redirectTo: 'enrolled', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
