import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-multi-select-input',
  templateUrl: './multi-select-input.component.html',
  styleUrls: ['./multi-select-input.component.scss'],
})
export class MultiSelectInputComponent implements OnInit {
  @Input() placeholder: string = '';
  @Input() label: string;
  @Input() options: any;
  @Input() key: string;
  @Input() displayCount: string = '';
  @Input() inputData?: any[];
  @Output() data = new EventEmitter();
  public selected: any[] = [];

  constructor() {}

  async ngOnInit() {
    if (this.inputData && this.inputData.length) {
      this.selected = this.inputData;
    }
  }

  public selectedOptions(event: any) {
    this.selected = event.value;
    this.data.emit(this.selected);
  }
}
