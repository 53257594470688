import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NewsInterface } from '@models/news.model';
import { BrowserProvider } from '@providers/browser/browser';
import { UtilsService } from '@services/utils/utils.service';

@Component({
  selector: 'app-news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['./news-card.component.scss'],
})
export class NewsCardComponent {
  @Input() new: NewsInterface;
  @Input() showBackground: boolean = false;

  get shouldShowBackground() { return this.showBackground && this.new.backgroundUrl }

  constructor(
    private router: Router,
    private browser: BrowserProvider,
    private utils: UtilsService,
  ) {

  }

  public async openLink() {
    if (this.new.internalRedirection) {
      await this.router.navigate(['enrolled', 'tabs', 'news', 'detail'], { queryParams: { id: this.new.docId } });
      return;
    }
    await this.browser.openFullscreen(this.new.url);
  }

  getScssUrl(url: string) {
    return this.utils.getScssUrl(url)
  }
}
