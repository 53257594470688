import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from '@models/subscription.model';


@Component({
  selector: 'app-add-date-modal',
  templateUrl: './add-date-modal.component.html',
  styleUrls: ['./add-date-modal.component.scss'],
})
export class AddDateModalComponent {
  public selectedDate: Date;

  constructor(
      private modalCtrl: ModalController
  ) {}

  public cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  public confirm() {
    return this.modalCtrl.dismiss(this.selectedDate, 'confirm');
  }

  public getDate(event) {
    this.selectedDate = event;
  }
}
