import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { QrCodeInterface, QrCodeRequestInterface } from '@models/qr-code.model';
import { increment } from '@angular/fire/firestore';

export const QR_VALIDATION_STATUS = {
    VALID: 'valid',
    NOT_FOUND: 'not-found',
    INACTIVE: 'inactive',
    INVALID: 'invalid',
    USED: 'used',
};    

const collection: string = 'qrCodes';
const subCollection: string = 'requests';


@Injectable()
export class QrCodesService {
    constructor (
        private afs: AngularFirestore,
    ) {}

    public async validateCode(code: string, userId: string): Promise<string> {
        try {
            const codeSnapshot = await this.afs.collection(collection).ref.doc(code).get();
            if (!codeSnapshot.exists) return QR_VALIDATION_STATUS.NOT_FOUND;
            const data = codeSnapshot.data() as QrCodeInterface;
            if (!data.active) return QR_VALIDATION_STATUS.INACTIVE;
            
            const requestCollection = codeSnapshot.ref.collection(subCollection);
            const userRequestSnapshot = await requestCollection
                .where('userId', '==', userId)
                .orderBy('date', 'desc')
                .limit(1)
                .get();
            
            if (userRequestSnapshot.empty) return QR_VALIDATION_STATUS.VALID;
            
            const request = userRequestSnapshot.docs[0].data() as QrCodeRequestInterface;
            request.date = (request.date as any).toDate();
            const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
            return Boolean(request.date < yesterday) ? QR_VALIDATION_STATUS.VALID : QR_VALIDATION_STATUS.USED;     
        } catch (error) {
            console.error('Error validating code', error);
            return QR_VALIDATION_STATUS.INVALID;
        }
    }

    public async addRequest(code: string, userId: string, data: any): Promise<boolean> {
        try {
            const codeSnapshot = this.afs.collection(collection).ref.doc(code);
            const requestCollection = codeSnapshot.collection(subCollection);
    
            await requestCollection.add({
                userId: userId,
                status: 'pending',
                date: new Date(),
                ...data,
            });
            await codeSnapshot.update({ totalUses: increment(1) });
            return true;
        } catch (error) {
            console.error('Error adding request', error)
            return false;
        }
    }
}
