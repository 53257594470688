import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Resource } from '@models/service.model';
import { imgFile } from '@components/extra/upload-button/upload-button.component';
import { LocalStorageProvider } from '@providers/local-storage/local-storage';

@Component({
  selector: 'app-upload-resource',
  templateUrl: './upload-resource.component.html',
  styleUrls: ['./upload-resource.component.scss'],
})
export class UploadResourceComponent implements OnInit {
  @Input() showButton: boolean = false;
  @Input() resource?: Resource[];

  public serviceImages: Resource[] = [{
    type: 'img',
    url: '',
    description: '',
    tag: 'client',
  }];

  public loadingPhoto: boolean;
  public userId: string

  @Output() valid: EventEmitter<any> = new EventEmitter();
  @Output() uploadData: EventEmitter<any> = new EventEmitter();
  @Output() uploadAction: EventEmitter<any> = new EventEmitter();

  constructor(
      private localStorage: LocalStorageProvider,
  ) {}

  async ngOnInit() {
    this.userId = await this.localStorage.getUserId();
    if (this.resource && this.resource.length) {
      this.serviceImages = this.resource;
    }
  }

  public async getDataUploaded(data: string, i: number) {
    this.serviceImages[i].url = data;
    this.uploadData.emit(this.serviceImages);
    this._isValid(i);
    this.loadingPhoto = false;
  }

  public change(event: string, i: number) {
    this._isValid(i);
  }

  public update() {
    console.log('Actualizar', this.serviceImages);
    this.uploadAction.emit(this.serviceImages);
  }

  public isValid(i: number)  {
    return this.serviceImages[i].url && this.serviceImages[i].description.length >= 3
  }

  public _isValid(i: number) {
    this.serviceImages[i].url && this.serviceImages[i].description.length >= 3 ?
        this.valid.emit(true) : this.valid.emit(false);
  }
}
