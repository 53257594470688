import { Injectable } from '@angular/core';
import { HttpInterceptorProvider } from '@providers/http-interceptor/http-interceptor';
import { loginMocks } from './auth.mock';
import { User } from '@models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpInterceptorProvider,
  ) {}

  public async login(mail: string, password: string) {
    const data = {user: {mail, password}};
    return this.http.post('login/', data, {status: 200, response: loginMocks.OK}).catch((error) => {
      console.error('Error in AuthService.login()', error);
      return Promise.reject(error);
    });
  }

}
