import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Service, SERVICE_CHIP, SERVICE_STATUS } from '@models/service.model';
import { UtilsService } from '@services/utils/utils.service';
import { Address } from '@models/address.model';

@Component({
  selector: 'app-punctual-service-card',
  templateUrl: './punctual-service-card.component.html',
  styleUrls: ['./punctual-service-card.component.scss'],
})
export class PunctualServiceCardComponent implements OnInit, OnChanges {
  @Input() services: Service[] = [];

  public loadingPage: boolean = true;

  @Output() onClick = new EventEmitter();

  constructor (
      private utilsSvc: UtilsService,
  ) {}

  ngOnInit() {
    if (this.services && this.services.length >= 1) {
      setTimeout(() => { this.loadingPage = false; }, 1000);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadingPage = true;
    if (this.services && this.services.length >= 1) {
      setTimeout(() => { this.loadingPage = false; }, 1000);
    }
  }

  public address(i) { return this.services[i].address as Address }

  public async callToAction(id: string) {
    this.onClick.emit(id);
  }

  public status(status) { return SERVICE_STATUS[status]; }

  public chip(status) { return SERVICE_CHIP[status]; }
}
