import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { loadMercadoPago } from "@mercadopago/sdk-js";
import { FirebaseServerService } from '@services/firebase-server/firebase-server.service';
import { UtilsService } from '@services/utils/utils.service';
import { environment } from 'src/environments/environment';
import { LocalStorageProvider } from '@providers/local-storage/local-storage';
import { Platform } from '@ionic/angular';
import { APP_VERSION } from '@constants/general.constants';

declare const window: any;

@Component({
  selector: 'app-create-payment',
  templateUrl: './create-payment.component.html',
  styleUrls: ['./create-payment.component.scss'],
})
export class CreatePaymentComponent implements OnInit, OnDestroy {
  @ViewChild('cardNumber') inputCardNumber: ElementRef<HTMLDivElement>;
  @ViewChild('expirationDate') inputExpirationDate: ElementRef<HTMLDivElement>;
  @ViewChild('securityCode') inputInputCVV: ElementRef<HTMLDivElement>;
  @ViewChild('cardholderName') inputName: ElementRef<HTMLInputElement>;
  @ViewChild('identificationType') inputNationalIdType: ElementRef<HTMLSelectElement>;
  @ViewChild('identificationNumber') inputNationalId: ElementRef<HTMLInputElement>;
  @ViewChild('cardholderEmail') inputEmail: ElementRef<HTMLInputElement>;
  @ViewChild('form') form: ElementRef<HTMLFormElement>;

  @Input() planId: string;
  @Input() subscriptionId: string;
  @Input() amount: number;
  @Input() description: string;
  @Input() action: 'payment' | 'subscription';
  @Input() skip: boolean = false;
  @Output() response: EventEmitter<any> = new EventEmitter();
  public loading = { paymentMethod: true, payment: false };
  public cardForm: any;
  public userId: string;
  public firstSubmit: boolean = false;
  public cardFormErrors: string[] = [];
  public version: string = APP_VERSION;

  get publicKey() {
    this.userId = this.localProvider.userId;
    if (!environment.production) {
      return this.action === 'subscription' ? environment.mercadopago.public_key_production : environment.mercadopago.public_key_test;
    }
    return environment.mercadopago.public_key_production
  }
  get buttonText() {
    return this.action === 'subscription' ? 'Suscribirme' : 'Pagar';
  }

  constructor(
    private platform: Platform,
    private firebaseServerService: FirebaseServerService,
    private utils: UtilsService,
    private localProvider: LocalStorageProvider,
  ) { }

  ngOnInit() {
    if (this.skip) {
      console.log('Payment skipped');
      this.response.emit({ status: 'approved' });
    } else {
      this.initializePaymentForm();
    }
  }

  ngOnDestroy(): void {
    if (this.cardForm) {
      try {
        this.cardForm.unmount();
      } catch (error) {
        console.warn('Card form already unmounted', error);
      }
    }
  }

  public async initializePaymentForm() {
    this.loading.paymentMethod = true;
    await loadMercadoPago();
    const mp = new window.MercadoPago(this.publicKey);

    this.cardForm = mp.cardForm({
      amount: String(this.amount),
      iframe: true,
      form: {
        id: "form-checkout",
        cardNumber: {
          id: "form-checkout__cardNumber",
          placeholder: "Numero de tarjeta",
        },
        expirationDate: {
          id: "form-checkout__expirationDate",
          placeholder: "MM/YY",
        },
        securityCode: {
          id: "form-checkout__securityCode",
          placeholder: "Código de seguridad",
        },
        cardholderName: {
          id: "form-checkout__cardholderName",
          placeholder: "Titular de la tarjeta",
        },
        issuer: {
          id: "form-checkout__issuer",
          placeholder: "Banco emisor",
        },
        installments: {
          id: "form-checkout__installments",
          placeholder: "Cuotas",
        },
        identificationType: {
          id: "form-checkout__identificationType",
          placeholder: "Tipo de documento",
        },
        identificationNumber: {
          id: "form-checkout__identificationNumber",
          placeholder: "Número del documento",
        },
        cardholderEmail: {
          id: "form-checkout__cardholderEmail",
          placeholder: "E-mail",
        },
      },
      callbacks: {
        onFormMounted: (error: any) => {
          if (error) return console.warn("Form Mounted handling error: ", error);
          console.log("Form mounted");
        },
        onFormUnmounted: () => {
          console.log("Form unmounted");
        },
        onReady: () => {
          this.loading.paymentMethod = false;

          const onKeyUpFunction = this.inputNationalId.nativeElement.onkeyup = (event) => {
            this.inputNationalId.nativeElement.value = this.utils.cleanRut(this.inputNationalId.nativeElement.value);
          };
          this.inputNationalId.nativeElement.onkeyup = onKeyUpFunction;
          this.inputNationalIdType.nativeElement.onchange = (event) => {
            if (this.inputNationalIdType.nativeElement.value === 'RUT') {
              this.inputNationalId.nativeElement.onkeyup = onKeyUpFunction;
            } else {
              this.inputNationalId.nativeElement.onkeyup = (event) => {};
            }
          };
        },
        onSubmit: async (event: any) => {
          console.log('Submit event:', event);
          event.preventDefault();

          try {
            const {
              paymentMethodId: payment_method_id,
              issuerId: issuer_id,
              cardholderEmail: email,
              amount,
              token,
              installments,
              identificationNumber,
              identificationType,
            } = this.cardForm.getCardFormData();
            this.cardForm.unmount();

            const requestObject = {
              token,
              issuer_id,
              payment_method_id,
              planId: this.planId || '',
              subscriptionId: this.subscriptionId || '',
              transaction_amount: Number(amount),
              installments: Number(installments),
              description: this.description || '',
              payer: {
                email,
                identification: {
                  type: identificationType,
                  number: identificationNumber,
                },
              },
            }

            if (this.action === 'subscription') {
              return await this.makePaymentSubscription(requestObject);
            } else {
              return await this.makePayment(requestObject);
            }

          } catch (error) {
            console.error("An error occurred while submitting the form", error);
            this.cardForm.unmount();
            this.response.emit({ status: 'submit-error' });
          }
        },
        onError: (error: any) => {
          console.error("Handling error", error);
          this.cardFormErrors = error
          this.inputExpirationDate.nativeElement.focus();
        },
      },
    });
  }

  public submit() {
    this.firstSubmit = true;
  }

  public getErrorMessage(field: 'inputEmail' | 'card' | 'inputName' | 'inputNationalIdType' | 'inputNationalId') {

    if (field === 'inputNationalId') {
      const invalidRutError = this.cardFormErrors.find((error: any) => error.message?.includes('Invalid cardholder.identification.number'));
      if (invalidRutError) return 'RUT inválido';
    }

    const inputs = ['inputName', 'inputNationalId', 'inputEmail']
    if (inputs.includes(field)) {
      return this.getSpecificErrorMessage((this[field].nativeElement as HTMLInputElement).validity);
    }

    if (field === 'card') {
      const cardNumberErrors = this.cardFormErrors.find((error: any) => error.message?.includes('cardNumber'));
      if (cardNumberErrors) return 'Número de tarjeta inválido';

      const cvvErrors = this.cardFormErrors.find((error: any) => error.message?.includes('securityCode'));
      if (cvvErrors) return 'CVV inválido';

      const dateErrors = this.cardFormErrors.find((error: any) => error.message?.includes('expirationYear') || error.message?.includes('expirationMonth'));
      if (dateErrors) return 'Fecha de expiración inválida';
    }

    return false
  }

  public getSpecificErrorMessage(validityState: any) {
    if (validityState.valid) return false;
    if (validityState.valueMissing) return 'Por favor, complete este campo';
    return 'Por favor, ingrese un dato válido';
  }

  public async makePayment(transactionRequest: any) {
    this.loading.payment = true;
    await this.firebaseServerService.callFunction('createPayment', { transactionRequest }).then((response) => {
      console.log('Payment response:', response);
      this.response.emit(response.status ? response : { ...response, status: 'error-no-status' });
    }).catch((error) => {
      console.error('Error making payment', error);
      this.response.emit({ status: 'error-calling-function' });
    });
  }

  public async makePaymentSubscription(transactionRequest: any) {
    this.loading.payment = true;
    await this.firebaseServerService.callFunction('createPaymentSubscription', { transactionRequest, userId: this.userId, platform: this.platform.platforms(), version: this.version })
        .then((response) => {
          console.log('Payment subscription response:', response);
          this.response.emit(response.status ? response : { ...response, status: 'error-no-status' });
        })
        .catch((error) => {
          console.error('Error making payment subscription', error);
          this.response.emit({ status: 'error-calling-function' });
        });
  }

}
