import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
})
export class GenericModalComponent {
  @Input() public title = '';
  @Input() public message = '';
  @Input() public primaryButtonText = '';
  @Input() public secondaryButtonText = '';
  @Input() public primaryButtonColor = 'primary';
  @Input() public secondaryButtonColor = 'primary';
  @Input() public icon = 'i-smartphone';
  @Input() public image = '';
  @Input() public pdf = '';
  @Input() public showButtons = true;
  @Input() public showCloseButton?: boolean = true;

  get onlyImage() { return this.image && !this.message && !this.pdf && !this.title && !this.icon && !this.showButtons; }

  constructor(
    private modalCtrl: ModalController,
  ) { }

  public dismissModal() {
    this.modalCtrl.dismiss();
  }

  public onPrimaryClick() {
    return this.modalCtrl.dismiss(null, 'confirm');
  }

  public onSecondaryClick() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }
}
