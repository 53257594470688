import { Injectable } from '@angular/core';
import firebase from 'firebase/compat';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { LocalStorageProvider } from '@providers/local-storage/local-storage';
import { take } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { User } from '@models/user.model';

@Injectable()
export class AuthFireService {
    private user: any;

    constructor(
        private afAuth: AngularFireAuth,
        private afs: AngularFirestore,
        private localStorageSvc: LocalStorageProvider,
    ) {
        this.afAuth.authState.subscribe((user: firebase.User) => {
            this.user = user;
        }, (error) => {
            console.error('Error in AuthService.constructor().afAuth.authState', error);
        });
    }

    public login(email: string, password: string): Promise<firebase.auth.UserCredential> {
        return this.afAuth.signInWithEmailAndPassword(email, password).catch((error) => {
            console.error('Error in AuthService.login()', error);
            return Promise.reject(error);
        });
    }

    public logout(): Promise<void> {
        return Promise.all([
            this.afAuth.signOut(),
            this.afAuth.signOut(),
            this.localStorageSvc.clear(),
        ]).then(() => {}).catch((error) => {
            console.error('Error in AuthService.logout()', error);
            return Promise.reject(error);
        });
    }

    public async register(email: string, password: string): Promise<string> {
        let step = '';
        try {
            step = 'create-user-with-email-and-password';
            const userCredential = await this.afAuth.createUserWithEmailAndPassword(email, password);
            step = 'get-id-token';
            const idToken = await userCredential.user.getIdToken(true);
            return Promise.resolve(userCredential.user.uid);
        } catch (error) {
            console.error(`Error in AuthService.register() at step ${step}`, error);
            return Promise.reject(error);
        }
    }

    public async isSignedIn(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (typeof this.user === 'undefined') {
                return this.afAuth.authState.pipe(take(1)).toPromise().then((user: firebase.User) => {
                    this.user = user;
                    resolve(!!this.user);
                }).catch((error) => {
                    console.error('Error in AuthService.isSignedIn().afAuth.authState', error);
                    reject(error);
                });
            }
            resolve(!!this.user);
        });
    }

    public async getUser(): Promise<firebase.User> {
        return new Promise((resolve, reject) => {
            if (typeof this.user === 'undefined') {
                return this.afAuth.authState.pipe(take(1)).toPromise().then((user: firebase.User) => {
                    this.user = user;
                    resolve(this.user);
                }).catch((error) => {
                    console.error('Error in AuthService.isSignedIn().afAuth.authState', error);
                    reject(error);
                });
            }
            resolve(this.user);
        });
    }

    async getUserId(): Promise<string> {
        return new Promise((resolve, reject) => {
            if (typeof this.user === 'undefined') {
                return this.afAuth.authState.pipe(take(1)).toPromise().then((user: firebase.User) => {
                    this.user = user;
                    resolve(this.user ? this.user.uid : null);
                }).catch((error) => {
                    console.error('Error in AuthService.isSignedIn().afAuth.authState', error);
                    reject(error);
                });
            }
            resolve(this.user ? this.user.uid : null);
        });
    }

    async getCurrentUser(): Promise<firebase.User> {
        return this.afAuth.currentUser;
    }

    async recoveryPassword(email: string): Promise<void> {
        return await this.afAuth.sendPasswordResetEmail(email)
            .then(() => {})
            .catch((err) => {
                console.error('Error in AuthService.recoveryPassword()', err);
                return Promise.reject(err);
            });
    }

    async loginAnonymously(rut: string): Promise<User> {
        const user = await this.getUserByRut(rut);
        if (!user) return Promise.reject('auth/user-not-found');
        return await this.afAuth.signInAnonymously()
            .then((response) => {
                return { ...user, temporalUid: response.user.uid};
            })
            .catch((err) => {
                console.error('Error in AuthService.signInAnonymously()', err);
                return Promise.reject(err);
            });
    }

    public async getUserByRut(rut: string): Promise<User | null> {
        const querySnapshot = await this.afs.collection('users').ref
            .where('companyUser','==',true)
            .where('rut','==',rut).get();
        const docs = querySnapshot.docs.map(doc => ({...doc.data() as User, docId: doc.id}));
        return docs && docs.length ? docs[0] : null;
    }

}
