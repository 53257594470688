import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentsService } from '@services/external-services/payments/payments.service';
import { loadMercadoPago } from '@mercadopago/sdk-js';
import { PaymentsRequest, PreferenceResponse } from '@models/payments.model';
import { ActivatedRoute } from '@angular/router';
import { RemoteConfigService } from '@services/firebase/remote-config.service';

@Component({
  selector: 'app-load-payments',
  templateUrl: './load-payments.component.html',
  styleUrls: ['./load-payments.component.scss'],
})
export class LoadPaymentsComponent implements OnInit {
  @Input() data: PaymentsRequest;

  public mp: any;
  public preferenceId: PreferenceResponse;

  private _publicKey: string;

  @Output() changePayment = new EventEmitter();

  constructor (
      private route: ActivatedRoute,
      private paymentsSvc: PaymentsService,
      private configSvc: RemoteConfigService,
  ) {}

  async ngOnInit() {
    this._publicKey = await this.configSvc.getStringValue('publicKeyMercadoPago');
    this.route.queryParams.subscribe(params => {
      this.changePayment.emit(params);
    });
    this.preferenceId = await this.paymentsSvc.createPreference(this.data);
    await this._initMercadoPago();
    this._openMercadoPago();
  }

  private async _initMercadoPago() {
    await loadMercadoPago();
    // @ts-ignore
    this.mp = new window.MercadoPago(this._publicKey, { locale: 'es-CL' });
  }

  private _openMercadoPago() {
    this.mp.bricks().create('wallet', 'wallet_container', {
      initialization: {
        preferenceId: this.preferenceId.paymentId,
        redirectMode: 'modal',
      }
    });

  }
}
