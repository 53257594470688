import { Component, OnInit } from '@angular/core';
import { NewsInterface } from '@models/news.model';
import { NewsService } from '@services/firebase/news/news.service';

@Component({
  selector: 'app-news-slider',
  templateUrl: './news-slider.component.html',
  styleUrls: ['./news-slider.component.scss'],
})
export class NewsSliderComponent implements OnInit {

  public loading = true;
  public featuredNews: NewsInterface[] = [];

  constructor(
    private newsSvc: NewsService,
  ) {

  }

  async ngOnInit() {
    this.featuredNews = await this.newsSvc.getFeaturedNews();
    this.loading = false;
  }

}
