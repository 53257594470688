import { Injectable } from '@angular/core';
import { REDEEM_STATUS } from '@constants/redeem.constants';
import { User } from '@models/user.model';
import { FirebaseServerService } from '@services/firebase-server/firebase-server.service';
import { UtilsService } from '@services/utils/utils.service';
import firebase from 'firebase/compat';

const collection = 'redeems';

@Injectable({
  providedIn: 'root'
})
export class ServerRedeemsService {
  public db: firebase.firestore.Firestore;
  private redeems: any[] = [];

  constructor(
      private fireServerSvc: FirebaseServerService,
      private utils: UtilsService,
  ) {
    this.db = fireServerSvc.getDb();
  }

  public async createRedeem(user: User, product: any, points: number, moneyAmount: number) {
    const ref = await this.db.collection(collection).add({
      rut: this.utils.cleanRut(user.rut || user.nationalId),
      email: user.email,
      productId: product.docId,
      product: product,
      points,
      moneyAmount,
      status: REDEEM_STATUS.PENDING,
      createdAt: new Date(),
    });
    return await new Promise((resolve, reject) => {
      ref.onSnapshot(async doc => {
        const redeem = doc.data();
        if (redeem.status === REDEEM_STATUS.REDEEM_POINTS_COMPLETED || redeem.status === REDEEM_STATUS.REDEEM_COMPLETED) {
          resolve(ref);
        } else if (redeem.status !== REDEEM_STATUS.PENDING) {
          reject(redeem);
        }
      }, reject);
    });
  }

  public async getRedeem(id: string, force = false): Promise<any> {
    const redeem = this.redeems.find(r => r.docId === id);
    if (redeem && !force) return redeem;
    if (force) {
      this.redeems = [];
    }
    const data = (await this.db.collection(collection).doc(id).get()).data() as any;
    if (!data) return null;
    return {
      ...data,
      createdAt: data.createdAt.toDate(), 
      docId: id,
      ref: this.db.collection(collection).doc(id),
    };
  }

  public async getRedeems(rut: string, force = false): Promise<any> {
    if (force || !this.redeems.length) {
      await this.loadRedeems(rut);
    }
    return this.redeems;
  }

  private async loadRedeems(rut: string) {
    const shouldShowStatus = [
      REDEEM_STATUS.REDEEM_COMPLETED,
      REDEEM_STATUS.REDEEM_POINTS_COMPLETED,
      REDEEM_STATUS.PAYMENT_COMPLETED,
      REDEEM_STATUS.PAYMENT_FAILED,
      REDEEM_STATUS.REFUND_POINTS_FAILED
    ];
    const ref = this.db.collection(collection)
      .where('rut', '==', this.utils.cleanRut(rut))
      .where('status', 'in', shouldShowStatus)
      .orderBy('createdAt', 'desc');
    const querySnapshot = await ref.get();
    this.redeems = querySnapshot.docs.map(doc => ({
      ...doc.data() as any,
      createdAt: doc.data().createdAt.toDate(),
      docId: doc.id,
      ref: doc.ref,
    }));
  }
}
