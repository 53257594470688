import { Injectable } from '@angular/core';
import { Toast } from '@capacitor/toast';

@Injectable()
export class ToastProvider {

  constructor() {}

  public async show(message: string) {
    await Toast.show({
      text: message,
      duration: 'long',
      position: 'bottom'
    });
  }

}
