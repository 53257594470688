import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { environment } from '../../../environments/environment';
import { getFunctions, httpsCallable } from 'firebase/functions';



@Injectable()
export class FirebaseServerService {

    private db: firebase.firestore.Firestore;

    constructor (
    ) {
        this.initializeApp()
    }
    
    public initializeApp() {
        const app = firebase.initializeApp(environment.firebaseServer, 'server');
        this.db = firebase.firestore(app); console.info('initializeApp Server');
        this.db.settings({ ignoreUndefinedProperties: true })
    }

    public getDb() {
        return this.db;
    }

    public async getDocument(collection: string, id: string) {
        const doc = await this.db.collection(collection).doc(id).get();
        if (!doc.exists) { console.error('No such document!'); }
        return { docId: doc.id, ...doc.data() as any };
    }

    public async createDoc(collection: string, id: string, data: any) {
        const cleanData = this.cleanDocRef(data);
        return await this.db.collection(collection).doc(id).set(cleanData, { merge: true });
    }

    public cleanDocRef(data: any) {
        for (const [key, value] of Object.entries(data)) {
            if (typeof value === 'object' && value !== null) {
                for (const [subKey, subValue] of Object.entries(value)) {
                    if (subKey === 'firestore') { delete data[key]; }
                    if (typeof subValue === 'object' && subValue !== null) {
                        for (const [subSubKey, subSubValue] of Object.entries(subValue)) {
                            const dataKey = data[key];
                            if (subSubKey === 'firestore' && dataKey) { delete dataKey[subKey]; }
                        }
                    }

                }
            }
        }
        return data;
    }

    async callFunction(name: string, params: any) {  
        const logHeader = '[callFunction] ';
        const functions = getFunctions(firebase.app(), environment.functionsRegion);
        const cloudFunction = httpsCallable(functions, name, { timeout: 120*1000 });
        console.info(logHeader + 'Params:', params)

        return await cloudFunction(params)
          .then((result: any) => {
            console.info(logHeader + 'Result from ' + name + ' function', result)
            const parsedResult = JSON.parse(result.data)
            if (parsedResult?.error) {
              console.error(logHeader + 'Error in callable function ' + name, parsedResult.error)
              throw new Error(parsedResult.error)
            }
    
            console.info(logHeader + 'Function ' + name + ' called Successfully')
            return parsedResult
          })
          .catch((error: any) => {
            console.error(logHeader + 'Error calling ' + name + ' function', error ? error.message ? error.message : error : 'No error message')
            throw error
          });
    }
}

