import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-fab',
  templateUrl: './fab.component.html',
  styleUrls: ['./fab.component.scss'],
})
export class FabComponent {
  @Input() firstIcon;
  @Input() secondIcon;

  @Output() firstIconClick = new EventEmitter();
  @Output() secondIconClick = new EventEmitter();

  public async clickOnFirstIcon() {
    this.firstIconClick.emit();
  }

  public async clickOnSecondIcon() {
    this.secondIconClick.emit();
  }
}
