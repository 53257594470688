import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';


@Component({
  selector: 'app-web-scanner',
  templateUrl: './web-scanner.component.html',
  styleUrls: ['./web-scanner.component.scss'],
})
export class WebScannerComponent implements OnInit, AfterViewInit {

  @Input() allowedFormats = [BarcodeFormat.QR_CODE];
  @Output() result = new EventEmitter();
  @ViewChild('scanner', { static: false })
  scanner: ZXingScannerComponent;
  hasPermission: boolean;
  qrResult: string;
  loading = {
    scanning: false,
    video: true,
  }
  flash = false

  constructor(
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.scanner.camerasNotFound.subscribe((devices: MediaDeviceInfo[]) => {
      console.error(
        'An error has occurred when trying to enumerate your video-stream-enabled devices.'
      );
    });

    this.scanner.permissionResponse.subscribe((answer: boolean) => {
      this.hasPermission = answer;
    });
  }

  async onCodeResult(result: string) {    
    if (result !== this.qrResult) {
      this.makeFlash()
      this.loading.scanning = true
      try {
        this.qrResult = result;
        this.result.emit(this.qrResult);
      } catch (error) {
        console.warn('[web-scanner] Error:', error)
      }
    } 
    this.loading.scanning = false;
  }

  videoStarted() {
    this.loading.video = false
  }

  makeFlash() {
    this.flash = true
    const canVibrate = window.navigator.vibrate as any
    if (canVibrate) window.navigator.vibrate(300)
    new Promise(resolve => setTimeout(resolve, 300)).then(() => {
      this.flash = false
    })
  }  

}
