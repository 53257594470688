import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { environment } from '../../../environments/environment';
import { User } from '@models/user.model';

@Injectable()
export class LocalStorageProvider {
  public user: User;
  public clientName: string;
  public userId: string;
  public pushToken: string;
  public enrolled: boolean;

  constructor() { }

  async clear(): Promise<void> {
    this.userId = null;
    this.user = null;
    this.pushToken = null;
    await Preferences.clear();
  }

  public set(key: string, value: any): Promise<boolean> {
    return Preferences.set({key, value}).then(() => true).catch((error) => {
      if (!environment.production) {
        console.error(`Error in LocalStorageProvider.save(${key})`, {error});
      }
      return false;
    });
  }

  public get(key: string): Promise<any> {
    return Preferences.get({key}).then((value) => {
      if (!value) {
        throw new Error(`${key} value is null`);
      }
      return value.value;
    }).catch((error) => {
      if (!environment.production) {
        console.error(`Error in LocalStorageProvider.load(${key})`, {error});
      }
      return null;
    });
  }

  public remove(key: string): Promise<boolean> {
    return Preferences.remove({key}).then(() => true).catch((error) => {
      if (!environment.production) {
        console.error(`Error in LocalStorageProvider.remove(${key})`, {error});
      }
      return false;
    });
  }

  async getUser(): Promise<User> {
    if (!this.user) {
      const user = await this.get('user');
      this.user = JSON.parse(user);
    }
    return this.user;
  }

  async setUser(user: User): Promise<void> {
    this.user = user;
    await this.set('user', JSON.stringify(user));
  }

  async getEnrolled(): Promise<boolean> {
    if (!this.enrolled) { this.enrolled = await this.get('enrolled'); }
    return this.enrolled;
  }

  async setEnrolled(enrolled: boolean): Promise<void> {
    this.enrolled = enrolled;
    await this.set('enrolled', enrolled);
  }

  async getClientName(): Promise<string> {
    if (!this.clientName) {
      this.clientName = await this.get('clientName');
    }
    return this.clientName;
  }

  async setClientName(clientName: string): Promise<void> {
    this.clientName = clientName;
    await this.set('clientName', clientName);
  }

  async getUserId(): Promise<string> {
    if (!this.userId) { this.userId = await this.get('userId'); }
    return this.userId;
  }

  async setUserId(userId: string): Promise<void> {
    this.userId = userId;
    await this.set('userId', userId);
  }

  async getPushToken(): Promise<string> {
    if (!this.pushToken) { this.pushToken = await this.get('pushToken'); }
    return this.pushToken;
  }

  async setPushToken(token: string): Promise<void> {
    this.pushToken = token;
    await this.set('pushToken', this.pushToken);
  }
}
