export const REDEEM_STATUS_MAP = {
    pending: {
        title: 'Pendiente',
        description: 'Tu solicitud de ha sido creada',
    },
    'payment-completed': {
        title: 'Canje completado',
        description: 'Tu pago ha sido completado',
    },
    'payment-failed': {
        title: 'Error en el pago',
        description: 'Tu pago ha sido rechazado',
    },
    'redeem-points-completed': {
        title: 'Puntos canjeados',
        description: 'Tus puntos han sido descontados',
    },
    'redeem-points-failed': {
        title: 'Error al canjear puntos',
        description: 'Tu solicitud ha sido rechazada',
    },
    'refund-points-completed': {
        title: 'Puntos devueltos',
        description: 'Tus puntos han sido devueltos',
    },
    'refund-points-failed': {
        title: 'Error al devolver puntos',
        description: 'Tu solicitud ha sido rechazada, contacta a un administrador',
    },
    'redeem-completed': {
        title: 'Canje completado',
        description: 'Tu canje ha sido completado',
    },
};

export const REDEEM_STATUS = {
    PENDING: 'pending',
    PAYMENT_COMPLETED: 'payment-completed',
    REDEEM_POINTS_COMPLETED: 'redeem-points-completed',
    REDEEM_POINTS_FAILED: 'redeem-points-failed',
    PAYMENT_FAILED: 'payment-failed',
    REFUND_POINTS_COMPLETED: 'refund-points-completed',
    REFUND_POINTS_FAILED: 'refund-points-failed',
    REDEEM_COMPLETED: 'redeem-completed',
};

