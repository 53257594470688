import { DocumentReference } from '@angular/fire/compat/firestore';
import { User } from '@models/user.model';
import { Plan } from '@models/plan.model';
import { Address } from '@models/address.model';
import { WorkShift } from '@models/work-shift.model';

export interface Subscription {
    docId: string ;
    createdAt: Date;
    date: Date;
    user: DocumentReference | User;
    plan: DocumentReference | Plan;
    address: DocumentReference | Address;
    workShift: WorkShift;
    status: Status;
    termsAndConditions: TermsAndConditions;
    services?: DocumentReference[];
    totalOfServices: number;
    servicesPerformed: number;
    lastServiceDate: Date;
    lastAttemptDate: Date;
    paymentId: string;
    payment: DocumentReference;
    startDate: Date;
    endDate: Date;
    migrated?: boolean;
}

export interface TermsAndConditions {
    approve: boolean;
    date: Date;
}

export const status: string[] = [
    'added-address',
    'view-resume',
    'approve-terms-and-conditions',
    'pending-payment',
    'error-in-payment',
    'successful-payment',
    'added-date-and-hour',
    'plan-created-successfully',
    'active',
    'deleted',
    'inactive',
];

export type Status = typeof status[number];

export const OK_STATUS = ['plan-created-successfully', 'active'];
export const PENDING_STATUS = [
    'added-address', 'view-resume', 'approve-terms-and-conditions',
    'pending-payment', 'error-in-payment', 'successful-payment', 'added-date-and-hour',
];

export const SUBSCRIPTION_STATUS = {
    active: 'Activo',
    inactive: 'Inactivo',
    pending: 'Pendiente',
    'plan-created-successfully': 'Plan creado',
    'added-address': 'Aceptar T&C',
    'pending-payment': 'Pendiente de pago',
}

export const SUBSCRIPTION_CHIP = {
    active: 'success',
    inactive: 'danger',
    pending: 'warning',
    'plan-created-successfully': 'success',
    'added-address': 'warning',
    'pending-payment': 'warning',
}
