import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ERROR_CODES } from '@constants/error-code.constants';

@Injectable()
export class HttpInterceptorProvider {
  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/json'
  });

  constructor(
    private http: HttpClient,
    ) { }

  public get(resource: string, params?: any): Promise<any> {
    return this.http.get(`${environment.baseBackend}${resource}`, { headers: this.headers, params }).toPromise()
      .then((response) => this.handleResponse('GET ' + resource, response))
      .catch((error) => this.handleError('GET ' + resource, error));
  }

  public delete(resource: string): Promise<any> {
    return this.http.delete(`${environment.baseBackend}${resource}`, { headers: this.headers }).toPromise()
      .then((response) => this.handleResponse('DELETE ' + resource, response))
      .catch((error) => this.handleError('DELETE ' + resource, error));
  }

  public post(resource: string, data?: any, mock?: any): Promise<any> {
    if (environment.mock && mock) {
      return this.handleMock(resource, mock);
    }
    return this.http.post(`${environment.baseBackend}${resource}`, JSON.stringify(data), { headers: this.headers }).toPromise()
      .then((response) => this.handleResponse('POST ' + resource, response))
      .catch((error) => this.handleError('POST ' + resource, error));
  }

  public put(resource: string, data?: any): Promise<any> {
    return this.http.put(`${environment.baseBackend}${resource}`, JSON.stringify(data), { headers: this.headers }).toPromise()
      .then((response) => this.handleResponse('PUT ' + resource, response))
      .catch((error) => this.handleError('PUT ' + resource, error));
  }

  public setAccessToken(accessToken: string) {
    if (accessToken === null) { this.headers = this.headers.delete('Authorization'); }
    this.headers = this.headers.set('Authorization', 'Bearer ' + accessToken);
  }

  private handleMock(resource: string, mock: any) {
    return new Promise((resolve, reject) => setTimeout(async () => {
      if (mock.status >= 200 && mock.status <= 399) {
        this.handleResponse(resource, mock.response).then((response) => resolve(response));
      } else {
        this.handleError(resource, mock).catch((error) => reject(error));
      }
    }, 2000));
  }

  private handleResponse(resource: string, response: any) {
    if (environment.enableApiDebug) {
      console.info(resource, response);
    }
    return Promise.resolve(response);
  }

  private async handleError(resource: string, error: any) {
    if (environment.enableApiDebug) {
      console.error(resource, error);
    }
    if ((error.status === ERROR_CODES.UNAUTHORIZED || error.status === ERROR_CODES.FORBIDDEN) && resource !== 'sesiones/') {
      await this.logout();
    }
    return Promise.reject({...error, message: error.error.message});
  }

  private async logout() {
    this.delete('sesiones/').then(async () => {
      this.setAccessToken(null);
    }).catch(async (error) => {
      console.error('error', error);
    });
  }

}
