import { Component, Input } from '@angular/core';
import { REDEEM_STATUS } from '@constants/redeem.constants';
import { UtilsService } from '@services/utils/utils.service';


@Component({
  selector: 'app-redeem-card',
  templateUrl: './redeem-card.component.html',
  styleUrls: ['./redeem-card.component.scss'],
})
export class RedeemCardComponent {

  @Input() redeem: any;
  @Input() fullSize: boolean = false;
  
  get loading() { return Boolean(!this.redeem) }
  get statusColor() {
    switch (this.redeem?.status) {
      case REDEEM_STATUS.PENDING:
      case REDEEM_STATUS.REDEEM_POINTS_COMPLETED: 
      case REDEEM_STATUS.PAYMENT_FAILED:
        return 'warning';
      case REDEEM_STATUS.PAYMENT_COMPLETED: 
        return 'success';
      case REDEEM_STATUS.REDEEM_POINTS_FAILED:
        return 'danger';
      default: return 'success';
    }
  }

  constructor (
    private utils: UtilsService,
  ) {}

  getScssUrl(url: string) {
    return this.utils.getScssUrl(url)
  }

}
