import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Photo } from '@capacitor/camera';
import { Button } from '@models/general.model';
import { PhotoService } from '@services/firebase/photo/photo.service';

export interface imgFile {
  name: string;
  filepath: string;
  size: number;
}

@Component({
  selector: 'app-upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.scss'],
})
export class UploadButtonComponent {
    @Input() showPercentageVal: boolean = false;
    @Input() showTrackSnapshot: boolean = false;
    @Input() fill: string = 'outline';
    @Input() userId: string;
    @Input() button: Button = { show: true, text: 'Tomar Foto', icon: 'camera-outline' };

    public uploading: boolean;
    public photos: string[] = [];

    @Output() uploadData: EventEmitter<any> = new EventEmitter();

    constructor(
        private photoSvc: PhotoService,
    ) {
        this.uploading = false;
    }

    public async attachPhoto() {
      this.uploading = true;
      const photo = await this.photoSvc.takePhoto();
      const url = await this.uploadPhoto(photo);
      // const webpath = photo.webPath;
      // this.photos.push(webpath);
      this.uploading = false;
      this.uploadData.emit(url);
    }

    public async uploadPhoto(photo: Photo) {
      const imageId = this.userId ?
          `filesStorage/${this.userId}/${new Date().getTime()}` :
              `filesStorage/${new Date().getTime()}`; 
      // const imageId = this._createUserRef().id + new Date().getTime();
      const url = await this.photoSvc.getFirebaseUrl(photo.webPath, imageId);
      if (url) return url as string;
      console.error('Error uploading image');
    }

}
