import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

const collection: string = 'constants';

@Injectable()
export class ConstantsFirebaseService {
    constructor (
        private afs: AngularFirestore,
    ) {}

    public async get(id: string): Promise<any> {
        const doc = await this.afs.collection(collection).doc(id).ref.get();
        if (!doc.exists) { console.error('No such document!'); }
        return { docId: doc.id, ...doc.data() as any };
    }
}
