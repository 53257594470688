import { Component, Input, OnInit } from '@angular/core';
import { ServerConfigService } from '@services/firebase-server/config/services.service';
import { WorkShift } from '@models/work-shift.model';

@Component({
  selector: 'app-information-of-hour-card',
  templateUrl: './information-of-hour-card.component.html',
  styleUrls: ['./information-of-hour-card.component.scss'],
})
export class InformationOfHourCardComponent implements OnInit {
    @Input() id: string;

    public workShifts: { firstTurn: WorkShift, secondTurn: WorkShift, dayTurn: WorkShift};
    public selected: string;

    constructor(
        private configSvc: ServerConfigService,
    ) {}

    async ngOnInit () {
        this.workShifts = await this.configSvc.getConfig('workShifts');
        const selectedShift = this.findShiftById();
        this.selected = selectedShift ? selectedShift.name : '09:00 - 18:00';
    }

    private findShiftById() {
        for (const shift of Object.values(this.workShifts)) {
            if (shift.id === this.id) { return shift; }
        }
        return null;
    }
}
