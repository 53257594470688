import { Injectable } from '@angular/core';
import { ExtraContentInterface } from '@models/extra-content.model';
import { FirebaseServerService } from '@services/firebase-server/firebase-server.service';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';

const collection: string = 'extra-content';

@Injectable()
export class ExtraContentService {

    private db: firebase.firestore.Firestore;

    constructor (
        private firebaseServer: FirebaseServerService
    ) {
        this.db = firebaseServer.getDb();
    }

    public async getExtraContents(): Promise<ExtraContentInterface[]> {
        const querySnapshot = await this.db.collection(collection)
            .where('active', '==', true)
            .where('target', 'array-contains', 'home')
            .orderBy('date', 'desc')
            .get();
        return querySnapshot.docs.map(doc => ({...doc.data() as ExtraContentInterface, docId: doc.id}));
    }

    public async getFeaturedExtraContents(): Promise<ExtraContentInterface[]> {
        const querySnapshot = await this.db.collection(collection)
            .where('active', '==', true)
            .where('featured', '==', true)
            .where('target', 'array-contains', 'home')
            .orderBy('date', 'desc')
            .get();
        return querySnapshot.docs.map(doc => ({...doc.data() as ExtraContentInterface, docId: doc.id}));
    }

    public async getExtraContent(id: string): Promise<ExtraContentInterface> {
        return (await this.db.collection(collection).doc(id).get()).data() as ExtraContentInterface;
    }
}
