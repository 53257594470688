import { Injectable, OnInit } from '@angular/core';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Platform } from '@ionic/angular';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Filesystem } from '@capacitor/filesystem';
import { LocalStorageProvider } from '@providers/local-storage/local-storage';
import { imgFile } from '@components/extra/upload-button/upload-button.component';
import * as url from 'url';

const IMG_DIR = 'rembre-images';

@Injectable()
export class CameraProvider implements OnInit {
    public userId: string

    constructor(
        private platform: Platform,
        private storage: AngularFireStorage,
        private localStorage: LocalStorageProvider,
    ) {}

    async ngOnInit () {
        this.userId = await this.localStorage.getUserId();
    }

    public async addNewToGallery() {
        const photo = await Camera.getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
            allowEditing: false,
            quality: 100,
        });
        const fileName = Date.now() + '.jpeg';
        return await this.savePicture(photo, fileName);
    }

    public async addNewToWithdraw() {
        const photo = await Camera.getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
            allowEditing: false,
            quality: 100,
        });
        const fileName = Date.now() + '.jpeg';
        const base64Data = await this.readAsBase64(photo);
        const storageRef = this.storage.ref(`filesStorage/${this.userId}/${fileName}`);
        const snapshot = await storageRef.putString(base64Data, 'data_url');
        const url =  await snapshot.ref.getDownloadURL();
        return { name: fileName, filepath: url } as imgFile;
    }

    private async savePicture(photo: Photo, fileName: string) {
        const base64Data = await this.readAsBase64(photo);
        const storageRef = this.storage.ref(`${IMG_DIR}/${fileName}`);
        const snapshot = await storageRef.putString(base64Data, 'data_url');
        return await snapshot.ref.getDownloadURL();
    }

    private async readAsBase64(photo: Photo) {
        const response = await fetch(photo.webPath);
        const blob = await response.blob();
        return await this.convertBlobToBase64(blob) as string;
    }

    private convertBlobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }
}
