export const MenuConstants = [
    { icon: 'person-outline', text: 'Información de Perfil', link: '/enrolled/tabs/account/profile', active: true, type: 'internal', order: 0 },
    { icon: 'cog-outline', text: 'Contraseña', link: '/enrolled/tabs/account/change-password', active: true, type: 'internal', order: 2 },
    { icon: 'notifications-off-outline', text: 'Configurar notificaciones', link: '/enrolled/tabs/account/configuration', active: true, type: 'internal', order: 3 },
    { icon: 'location-outline', text: 'Direcciones', link: '/enrolled/tabs/locations', active: true, type: 'internal', order: 4 },
    { icon: 'wallet-outline', text: 'Subscripciones', link: '/enrolled/tabs/subscriptions', active: true, type: 'internal', order: 5 },
  //  { icon: 'home-outline', text: 'Sitio de web', link: 'https://www.colina.cl/direccion-de-medioambiente/', active: true, type: 'external', order: 5 },
    { icon: 'radio-outline', text: 'Noticias', link: '/enrolled/tabs/news', active: true, type: 'internal', order: 6 },
    { icon: 'book-outline', text: 'Contenido útil', link: '/enrolled/tabs/extra-content', active: true, type: 'internal', order: 7 },
    { icon: 'log-out-outline', text: 'Cerrar sesión', link: '/enrolled/tabs/account/logout', active: true, type: 'internal', order: 10 },
];

// { icon: 'document-text-outline', text: 'Historial de retiros', link: '', active: true, type: 'internal', order: 1 },
