import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonSelect } from '@ionic/angular';
import { AddressesService } from '@services/firebase/addresses/addresses.service';
import { Address } from '@models/address.model';
import { LocalStorageProvider } from '@providers/local-storage/local-storage';

@Component({
  selector: 'app-select-subscription',
  templateUrl: './select-subscription.component.html',
  styleUrls: ['./select-subscription.component.scss'],
})
export class SelectSubscriptionComponent implements OnInit {
  @ViewChild(IonSelect) select: IonSelect;

  public userId: string;
  public addresses: Address[] = [];
  public selectedAddress: string = '';

  @Output() getAddress = new EventEmitter();

  constructor (
      private addressSvc: AddressesService,
      private localStorage: LocalStorageProvider,
  ) {
  }

  async ngOnInit() {
    this.userId = await this.localStorage.getUserId();
    this.addresses = await this.addressSvc.getAddressesByUser(this.userId);
    this.addresses.push({ name: 'Puntuales' } as Address);
    if (this.addresses.length === 1) this.selectedAddress = this.addresses[0].name;
  }

  public selected(event){
    this.selectedAddress = event;
    const address = this.addresses.find(element => element.name === event);
    this.getAddress.emit(address);
  }

  public async openSelect() {
    await this.select.open()
  }
}
