import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

const collection: string = 'config';

@Injectable()
export class ConfigService {

    private configs = {};

    constructor (
        private afs: AngularFirestore,
    ) {}

    public async getConfig(id: string, force = false): Promise<any> {
        if (this.configs[id] && !force) return this.configs[id];
        const doc = await this.afs.collection(collection).doc(id).ref.get();
        if (!doc.exists) { console.error('No such document!'); }
        this.configs[id] = { docId: doc.id, ...doc.data() as any };
        return this.configs[id];
    }

}
