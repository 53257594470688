import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { OK_STATUS, Subscription } from '@models/subscription.model';
import { FirebaseService } from '@services/firebase/firebase.service';

const collection: string = 'subscriptions';

@Injectable()
export class SubscriptionsService {
    constructor (
        private fireSvc: FirebaseService,
        private afs: AngularFirestore,
    ) {}

    public createRef(col: string, docId: string) {
        return this.afs.collection(col).doc(docId).ref;
    }

    public async getSubscription(id: string): Promise<Subscription> {
        const doc = await this.afs.collection(collection).doc(id).ref.get();
        if (!doc.exists) { console.error('No such document!'); }
        const document = { docId: doc.id, ...doc.data() as Subscription };
        await this._convertDoc(document);
        return document;
    }

    public async getSubscriptionsByUser(userId: string): Promise<Subscription[]> {
        const userRef = this.createRef('users', userId);
        const querySnapshot = await this.afs.collection(collection).ref
            .where('status', '==', 'active')
            .where('user', '==', userRef)
            .orderBy('createdAt', 'desc')
            .get();
        const documents = querySnapshot.docs.map(doc => ({...doc.data() as Subscription, docId: doc.id}));
        for (let document of documents) { await this._convertDoc(document); }
        return documents;
    }

    public async getAllSubscriptionsByUser(userId: string): Promise<Subscription[]> {
        const userRef = this.createRef('users', userId);
        const querySnapshot = await this.afs.collection(collection).ref
            .where('user', '==', userRef)
            .orderBy('createdAt', 'desc')
            .get();
        const documents = querySnapshot.docs.map(doc => ({...doc.data() as Subscription, docId: doc.id}));
        for (let document of documents) { await this._convertDoc(document); }
        return documents;
    }

    public async getSubscriptionsByAddress(addressId: string): Promise<Subscription[]> {
        const addressRef = this.createRef('addresses', addressId);
        const querySnapshot = await this.afs.collection(collection).ref
            .where('address', '==', addressRef)
            .where('status', 'in', OK_STATUS)
            .get();
        const documents = querySnapshot.docs.map(doc => ({...doc.data() as Subscription, docId: doc.id}));
        for (let document of documents) { await this._convertDoc(document); }
        return documents;
    }

    public async createSubscription(data: Subscription): Promise<any> {
        return await this.afs.collection(collection).add(data)
            .then((docRef) => { return docRef.id; })
            .catch((error) => { return error; });
    }

    public async updateSubscription(id: string, data: any): Promise<void> {
        if (data && data.docId) delete data.docId;
        if (data) await this.afs.collection(collection).doc(id).set(data, { merge: true });
    }

    /*
    * AUX Functions
    * */

    private async _formatRef(object: Subscription, key: string) {
        // @ts-ignore
        object[key] = await this.fireSvc.get(object[key]);
    }

    private convertToDate(object: Subscription, key: string) {
        object[key] = object[key].toDate();
    }

    private async _convertDoc(document: Subscription) {
        if (document.user) await this._formatRef(document, 'user');
        if (document.plan) await this._formatRef(document, 'plan');
        if (document.address) await this._formatRef(document, 'address');
        if (document.createdAt) this.convertToDate(document, 'createdAt');
    }

}
