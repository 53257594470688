export const MOBILE_BREAKPOINT = 768;
export const ANDROID_ID = '';
export const IOS_ID = '';

export const FORCE_MESSAGE_TITLE = 'Actualiza tu app';
export const FORCE_UPDATE_MESSAGE = 'Por razones de seguridad, debes actualizar tu app.';

export const ERROR_HEADER = 'Lo sentimos';
export const ERROR_DEFAULT_MSG = 'En estos momentos no podemos atender tu solicitud';
export const CLOSE_BUTTON_MSG = 'Cerrar';
export const CONTACT_US = 'Te invitamos a contactarnos a través de nuestra página web';

export const SAD_FACE_PURPLE_ICON = 'i-sad-face-purple';

export const MODAL_PRIMARY_ACTION = 'primaryButtonPressed';
export const MODAL_SECONDARY_ACTION = 'secondaryButtonPressed';

export const PLATFORM_NAMES = {
  android: 'android',
  ios: 'ios',
};
