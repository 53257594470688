import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Service, SERVICE_CHIP, SERVICE_STATUS } from '@models/service.model';

@Component({
  selector: 'app-movements',
  templateUrl: './movements.component.html',
  styleUrls: ['./movements.component.scss'],
})
export class MovementsComponent implements OnInit {
  @Input() firstTab: string;
  @Input() secondTab: string;
  @Input() firstList: Service[];
  @Input() secondList: Service[];

  public tabActive: string = 'first';

  @Output() detailClick = new EventEmitter();

  constructor(
  ) {}

  ngOnInit() {
  }

  public status(status) { return SERVICE_STATUS[status]; }

  public chip(status) { return SERVICE_CHIP[status]; }

  public convertDate(date: any) {
    try {
        return date.toDate();
    } catch (error) {
        return date;
    }
  }

  public active(tab: string) {
    this.tabActive = tab;
  }
}
