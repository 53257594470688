import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { combineLatest } from 'rxjs';

interface UploadedFile {
    bucket: string;
    path: string;
    url: string;
}

@Injectable({
    providedIn: 'root'
})
export class FirebaseStorageService {
    constructor (
        private afStorage: AngularFireStorage,
    ) {}

    public uploadFileToFireStorage(file: File, folder: string): Promise<UploadedFile> {
        return new Promise((resolve, reject) => {
            const path: string = this.generatePath(file, folder);
            this.afStorage.upload(path, file)
                .then(() => {
                    combineLatest([
                        this.afStorage.ref(path).getMetadata(),
                        this.afStorage.ref(path).getDownloadURL(),
                    ]).subscribe(([metadata, url]) => {
                        resolve({
                            bucket: metadata.bucket,
                            path: metadata.fullPath,
                            url,
                        });
                    });
                })
                .catch((err) => { reject(err); });
        });
    }

    private generatePath(file: File, folder: string) {
        let path: string;
        path = `${folder}/${file.name}`;
        return path;
    }
}
