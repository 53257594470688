import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PERFORMED_STATUS, Service, TO_PERFORM_STATUS } from '@models/service.model';
import { Subscription, SUBSCRIPTION_CHIP, SUBSCRIPTION_STATUS } from '@models/subscription.model';
import { Button } from '@models/general.model';
import { Address } from '@models/address.model';
import { UtilsService } from '@services/utils/utils.service';
import { Plan } from '@models/plan.model';

@Component({
  selector: 'app-service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.scss'],
})
export class ServiceCardComponent implements OnInit, OnChanges {
  @Input() services: Service[] = [];
  @Input() subscription: Subscription;
  @Input() fillTentative: boolean = false;
  @Input() button: Button = { show: false, text: '', icon: '' };

  public loadingPage: boolean = true;
  public servicesPerformed: Service[] = [];
  public servicesToPerform: Service[] = [];
  public planInfo: Plan;
  public withoutServices: boolean = true;

  @Output() onClick = new EventEmitter();

  constructor (
      private utilsSvc: UtilsService,
  ) {}

  get hasServices() { return this.services && this.services.length >= 1; }
  get address() { return this.subscription.address as Address }
  get fullAddress() { return this.address.street + ',' + this.address.detail + ',' + this.address.commune }
  get hasServicesToPerform() { return this.servicesToPerform && this.servicesToPerform.length >= 1; }

  ngOnInit() {
    this.planInfo = this.subscription.plan as Plan;
    this.checkInfo();
    if (this.fillTentative) this.fillTentativeData();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadingPage = true;
    this.checkInfo();
    if (this.fillTentative) this.fillTentativeData();
  }

  public checkInfo() {
    if (this.hasServices) {
      this.withoutServices = false;
      this._cleanServices();
      this._sortServices();
      setTimeout(() => { this.loadingPage = false; }, 1500);
    } else {
      this.withoutServices = true;
      this._cleanServices();
      setTimeout(() => { this.loadingPage = false; }, 1500);
    }
  }

  public async callToAction(id: string) {
    this.onClick.emit(id);
  }

  public status(status: string) { return SUBSCRIPTION_STATUS[status]; }

  public chip(status: string) { return SUBSCRIPTION_CHIP[status]; }

  private _sortServices() {
    for (const service of this.services) {
      if (TO_PERFORM_STATUS.indexOf(service.status) >= 0) {
        this.servicesToPerform.push(service);
        if (this.servicesToPerform)
          this.servicesToPerform = this.utilsSvc.sortByAttribute(this.servicesToPerform, 'id')
      }
      if (PERFORMED_STATUS.indexOf(service.status) >= 0) {
        this.servicesPerformed.push(service);
        if (this.servicesPerformed)
          this.servicesPerformed = this.utilsSvc.sortByAttribute(this.servicesPerformed, 'id')
      }
    }
  }

  private _cleanServices() {
    if (this.servicesToPerform.length >= 1) this.servicesToPerform = [];
    if (this.servicesPerformed.length >= 1) this.servicesPerformed = [];
  }

  private fillTentativeData() {
    if (this.hasServicesToPerform) {
      const tentativesServices= this.createServices(this.servicesToPerform[this.servicesToPerform.length - 1].date, this.subscription.servicesPerformed + this.servicesToPerform.length, this.subscription.totalOfServices)
      this.servicesToPerform = [...this.servicesToPerform, ...tentativesServices]
    }
    if (!this.hasServicesToPerform) {
      const tentativesServices= this.createServices(this.subscription.lastServiceDate, this.subscription.servicesPerformed, this.subscription.totalOfServices)
      this.servicesToPerform = [...this.servicesToPerform, ...tentativesServices]
    }
  }

  public convertDate(date: any) {
    try {return date.toDate();} catch (error) {return date;}
  }

  private createServices(startDate: any, initialId: number, n: number) {
    const result = [];
    let currentDate = this.convertDate(startDate);
    currentDate.setDate(currentDate.getDate() + 14);
    for (let i = initialId + 1; i <= n; i++) {
      result.push({
        id: i,
        date: new Date(currentDate),
        status: 'tentative'
      });
      currentDate.setDate(currentDate.getDate() + 14);
    }
    return result;
  }
}
