import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Service } from '@models/service.model';
import { Address } from '@models/address.model';
import { RouteFilterDayService } from '@services/firebase-server/route-filter-day/route-filter-day.service';
import { Subscription } from '@models/subscription.model';

@Component({
  selector: 'app-next-service',
  templateUrl: './next-service.component.html',
  styleUrls: ['./next-service.component.scss'],
})
export class NextServiceComponent implements OnInit, OnChanges {
  @Input() service: Service;
  @Input() subscription: Subscription;

  public loadingPage: boolean = true;
  public filters: any;
  public day: string;

  @Output() onClick = new EventEmitter();

  constructor(
      private routeFilterDaySvc: RouteFilterDayService
  ) {
  }

  get address() { return this.service.address as Address }
  get fullAddress() { return this.address.street + ',' + this.address.detail + ',' + this.address.commune }

  async ngOnInit() {
    await this.loadData();
    this.loadingPage = false;
  }

  async ngOnChanges(changes: SimpleChanges) {
    await this.loadData();
  }

  public onClickButton() {
    this.onClick.emit();
  }

  private async loadData() {
    console.log(this.service);
    if (!this.service) {
      await this.createService('subscription');
      return;
    }
    if (!this.isDateGreater()) {
      await this.createService('service');
      return;
    }
    if (this.service) {
      this.service.date = this.convertDate(this.service.date);
    }
  }

  private isDateGreater() {
    if (!this.service.date) return;
    const itemDateConverted = this.convertDate(this.service.date);
    const currentDate = new Date();
    return itemDateConverted >= currentDate;
  }

  private async createService(type: 'subscription' | 'service') {
    if (type === 'service')
      this.service = {date: this.addDaysToDate(this.service.date.toDate()), status: 'pending', address: this.service.address } as Service;
    if (type === 'subscription')
      this.service = {date: await this.findSubDay(), status: 'pending', address: this.subscription.address} as Service;
  }

  private addDaysToDate(date: Date) {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 14);
    return newDate;
  }

  private async findSubDay() {
    const key = await this.filterDay();
    return this.getDateOfWeekday(key);
  }

  private async filterDay() {
    this.filters = await this.routeFilterDaySvc.getFilters();
    return this.findKeyByName();
  }

  private findKeyByName() {
    const address = this.subscription.address as Address;
    for (const item of this.filters) {
      const filterMatch = item.filter.find((filterItem: any) => filterItem.name.toUpperCase() === address.commune.toUpperCase());
      if (filterMatch) return item.key;
    }
    return null;
  }

  private getDateOfWeekday(weekday: number) {
    const today = new Date();
    const currentDay = today.getDay();
    let difference = weekday - currentDay;
    if (difference < 0) difference += 7;
    const targetDate = new Date(today);
    targetDate.setDate(today.getDate() + difference);
    return targetDate;
  }

  private convertDate(date: any) {
    try {
        return date.toDate();
    } catch (error) {
        return date;
    }
  }
}
