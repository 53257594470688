import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Plan } from '@models/plan.model';
import { Button } from '@models/general.model';
import { PlansService } from '@services/firebase/plans/plans.service';
import { UtilsService } from '@services/utils/utils.service';

@Component({
  selector: 'app-plans-form',
  templateUrl: './plans-form.component.html',
  styleUrls: ['./plans-form.component.scss'],
})
export class PlansFormComponent implements OnInit {
  @Input() buttonsOptions: boolean = true;
  @Input() firstButton: Button = { show: false, text: '' };
  @Input() secondButton: Button = { show: false, text: '' };

  public plans: Plan[] = [];
  public selectedPlan: Plan;
  public loadingPage: boolean = true;
  public loading: boolean;

  @Output() getData = new EventEmitter();
  @Output() dismissButton = new EventEmitter();

  constructor (
      private utilsSvc: UtilsService,
      private plansSvc: PlansService,
  ) {
  }

  async ngOnInit () {
    this.plans = await this.plansSvc.getPlans();
    this.plans = this.utilsSvc.sortByAttribute(this.plans, 'order');
    setTimeout(() => { this.loadingPage = false; }, 1000);
  }

  public sendData() {
    this.getData.emit(this.selectedPlan);
  }

  public clickOnDismissButton() {
    this.dismissButton.emit();
  }

  public togglePlan(index: number){
    this.plans[index].show = !this.plans[index].show;
  };

  public selectPlan(index: number) {
    for(let i = 0; i < this.plans.length; i++) {
      if (this.plans[i].selected) this.plans[i].selected = false;
    }
    this.plans[index].selected = !this.plans[index].selected;
    this.selectedPlan = this.plans[index];
    this.sendData();
  }
}
