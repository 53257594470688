import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Chat } from '@models/chat.model';
import { map, Observable } from 'rxjs';

const collection: string = 'chats';

@Injectable({
    providedIn: 'root'
})
export class ChatsService {
    constructor (
        private afs: AngularFirestore,
    ) {
    }

    public createRef(col: string, docId: string) {
        return this.afs.collection(col).doc(docId).ref;
    }

    public getChatByUserObs(userId): Observable<Chat[]> {
        const userRef = this.createRef('users', userId);
        const documents = this.afs.collection(collection, ref =>
            ref
                .where('user', '==', userRef)
                .orderBy('date', 'asc'),
        ).valueChanges({idField: 'docId'});
        return documents.pipe(map((services: Chat[]) => services.map(service => ({...service, ref: this.createRef('chats', service.docId) } as Chat))));
    }

    public async getChatByUser(userId): Promise<Chat[]> {
        const userRef = this.createRef('users', userId);
        const querySnapshot = await this.afs.collection(collection).ref
            .where('user', '==', userRef).get();
        return querySnapshot.docs.map(doc => ({...doc.data() as Chat, docId: doc.id}));
    }

    public async createMessage(data): Promise<any> {
        try {
            const ref = await this.afs.collection(collection).add(data);
            await data.user.set({ pendingChat: true }, { merge: true });
            return ref.id;
        } catch (error) {
            return error;
        }
    }

    public getNewMessageRef() {
        return this.afs.collection(collection).doc().ref;
    }
}
