import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { FirebaseStorageService } from '@services/firebase/firebase-storage.service';

@Injectable({
  providedIn: 'root'
})
export class PhotoService {
  
  constructor(
    private storage: FirebaseStorageService,
  ) { }

  public async takePhoto() {
    return await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 100
    });
  }

  public async getFirebaseUrl(webpath: string, filename: string, folder = 'chat-images') {
    const blob = await this.webpathToBlob(webpath);
    const file = new File([blob], `${filename}.jpeg`, { type: 'image/jpeg' });
    return this.uploadImage(file, folder);
  }

  public async uploadImage(file: File, folder: string) {
    return await this.storage.uploadFileToFireStorage(file, folder)
      .then((file) => {
        return file?.url;
      })
      .catch((error: any) => {
        console.error('Error uploading file:', error)
        return null;
      });
  }

  private webpathToBlob(webpath: string) {
    return fetch(webpath).then(response => response.blob());
  }
}
