import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';

@Component({
  selector: 'app-search-address',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  @Output() address = new EventEmitter();
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;

  public addressFormatted: any;
  public options: any = { componentRestrictions: { country: 'CL' } };

  handleAddressChange(address: Address) {
    const latitude = address.geometry.location.lat();
    const longitude = address.geometry.location.lng();
    const position = { latitude, longitude };
    this._setAddress(address.formatted_address, position);
  }

  private _setAddress(address: string, position: any) {
    const formattedAddress = address.split(',');
    const communePostal = this._splitFirstOccurrence(formattedAddress[1].slice(1), ' ');
    this.addressFormatted = {
      street: formattedAddress[0],
      postalCode: parseInt(communePostal[0]),
      commune: communePostal[1],
      region: formattedAddress[2].slice(1),
      country: formattedAddress[3].slice(1),
      position: {
        lat: position.latitude,
        lng: position.longitude,
      },
    };
    this.address.emit(this.addressFormatted);
  }

  private _splitFirstOccurrence(str: string, separator: any) {
    let [first, ...rest] = str.split(separator);
    let remainder = rest.join(' ');
    const postalCode = parseInt(first);
    if (Number.isNaN(postalCode)) { first = null; remainder = str; }
    return [first, remainder];
  }
}
