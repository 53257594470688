import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceCardComponent } from '@components/cards/service-card/service-card.component';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from '@components/layout/header/header.component';
import { RouterLink } from '@angular/router';
import { FancyToggleComponent } from '@components/extra/fancy-toggle/fancy-toggle.component';
import { PlansFormComponent } from '@components/forms/plans-form/plans-form.component';
import { UserFormComponent } from '@components/forms/user-form/user-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddAddressModalComponent } from '@components/modals/add-address-modal/add-address-modal.component';
import { LoadingComponent } from '@components/extra/loading/loading.component';
import { SkeletonCardComponent } from '@components/cards/skeleton-card/skeleton-card.component';
import { AddressesComponent } from '@components/forms/addresses/addresses.component';
import { CreditCardComponent } from '@components/extra/credit-card/credit-card.component';
import { DateFormComponent } from '@components/forms/date-form/date-form.component';
import { HourFormComponent } from '@components/forms/hour-form/hour-form.component';
import { FabComponent } from '@components/extra/fab/fab.component';
import { WithoutPlanComponent } from '@components/cards/without-plan/without-plan.component';
import { SelectSubscriptionComponent } from '@components/extra/select-subscription/select-subscription.component';
import { SubscriptionCardComponent } from '@components/cards/subscription-card/subscription-card.component';
import { MovementsComponent } from '@components/extra/movements/movements.component';
import { UploadResourceComponent } from '@components/extra/upload-resource/upload-resource.component';
import { UploadButtonComponent } from '@components/extra/upload-button/upload-button.component';
import { FormatFileSizePipe } from '@utils/pipes/format-file-size.pipe';
import { LoadPaymentsComponent } from '@components/payments/load-payments/load-payments.component';
import { PunctualServiceCardComponent } from '@components/cards/punctual-service-card/punctual-service-card.component';
import { ButtonsMenuComponent } from '@components/layout/buttons-menu/buttons-menu.component';
import { ChatBoxComponent } from '@components/chat/chat-box/chat-box.component';
import { GenericModalComponent } from '@components/modals/generic-modal/generic-modal.component';
import { AddDateModalComponent } from '@components/modals/add-date-modal/add-date-modal.component';
import { SearchComponent } from '@components/maps/search/search.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NewsCardComponent } from '@components/cards/news-card/news-card.component';
import { EvaluateServiceComponent } from '@components/extra/evaluate-service/evaluate-service.component';
import { MoneyInputComponent } from '@components/input/money-input/money-input.component';
import { TermsAndConditionsComponent } from '@components/input/terms-and-conditions/terms-and-conditions.component';
import { TendersModalComponent } from '@components/modals/tenders-modal/tenders-modal.component';
import { MultiSelectInputComponent } from '@components/input/multi-select-input/multi-select-input.component';
import { CustomFormComponent } from '@components/forms/custom-form/custom-form.component';
import { CreatePaymentComponent } from './payments/create-payment/create-payment.component';
import { WebScannerComponent } from './scanners/web-scanner/web-scanner.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { CapacitorScannerComponent } from './scanners/capacitor-scanner/capacitor-scanner.component';
import { LateralBannerComponent } from './cards/lateral-banner/lateral-banner.component';
import { RedeemProductCardComponent } from './cards/redeem-product-card/redeem-product-card.component';
import { RedeemCardComponent } from './cards/redeem-card/redeem-card.component';
import {
    InformationOfDayCardComponent
} from '@components/cards/information-of-day-card/information-of-day-card.component';
import {
    InformationOfHourCardComponent
} from '@components/cards/information-of-hour-card/information-of-hour-card.component';
import { LinkifyDirectivePipe } from '@utils/pipes/linkyfy.pipe';
import { SanitizeHtmlPipe } from '@utils/pipes/sanitize-html.pipe';
import { NewsSliderComponent } from './extra/news-slider/news-slider.component';
import { ExtraContentCardComponent } from './cards/extra-content-card/extra-content-card.component';
import { ExtraContentSliderComponent } from './extra/extra-content-slider/extra-content-slider.component';
import { RichInnerTextComponent } from './extra/rich-inner-text/rich-inner-text.component';
import { ServicesCardComponent } from '@components/cards/services-card/services-card.component';
import { NextServiceComponent } from '@components/cards/next-service/next-service.component';


const CARDS_COMPONENTS = [
    PunctualServiceCardComponent,
    ServiceCardComponent,
    SkeletonCardComponent,
    SubscriptionCardComponent,
    WithoutPlanComponent,
    NewsCardComponent,
    ExtraContentCardComponent,
    LateralBannerComponent,
    RedeemProductCardComponent,
    RedeemCardComponent,
    InformationOfDayCardComponent,
    InformationOfHourCardComponent,
    ServicesCardComponent,
    NextServiceComponent,
];

const CHAT_COMPONENTS = [
    ChatBoxComponent,
];

const EXTRA_COMPONENTS = [
    CreditCardComponent,
    FabComponent,
    FancyToggleComponent,
    LoadingComponent,
    MovementsComponent,
    SelectSubscriptionComponent,
    UploadButtonComponent,
    UploadResourceComponent,
    EvaluateServiceComponent,
    NewsSliderComponent,
    ExtraContentSliderComponent,
    RichInnerTextComponent,
];

const FORMS_COMPONENTS = [
    AddressesComponent,
    CustomFormComponent,
    DateFormComponent,
    HourFormComponent,
    PlansFormComponent,
    UserFormComponent,
];

const INPUT_COMPONENTS = [
    MoneyInputComponent,
    TermsAndConditionsComponent,
    MultiSelectInputComponent,
];

const LAYOUT_COMPONENTS = [
    ButtonsMenuComponent,
    HeaderComponent,
];

const MAPS_COMPONENTS = [
    SearchComponent,
];

const MODALS_COMPONENTS = [
    AddAddressModalComponent,
    GenericModalComponent,
    AddDateModalComponent,
    TendersModalComponent,
];

const PAYMENTS_COMPONENTS = [
    LoadPaymentsComponent,
    CreatePaymentComponent,
];

const SCANNER_COMPONENTS = [
    WebScannerComponent,
    CapacitorScannerComponent,
];


@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        RouterLink,
        ReactiveFormsModule,
        FormsModule,
        GooglePlaceModule,
        ZXingScannerModule,
    ],
    exports: [
        CommonModule,
        ...CARDS_COMPONENTS,
        ...CHAT_COMPONENTS,
        ...EXTRA_COMPONENTS,
        ...FORMS_COMPONENTS,
        ...INPUT_COMPONENTS,
        ...LAYOUT_COMPONENTS,
        ...MAPS_COMPONENTS,
        ...MODALS_COMPONENTS,
        ...PAYMENTS_COMPONENTS,
        ...SCANNER_COMPONENTS,
    ],
    declarations: [
        ...CARDS_COMPONENTS,
        ...CHAT_COMPONENTS,
        ...EXTRA_COMPONENTS,
        ...FORMS_COMPONENTS,
        ...INPUT_COMPONENTS,
        ...LAYOUT_COMPONENTS,
        ...MAPS_COMPONENTS,
        ...MODALS_COMPONENTS,
        ...PAYMENTS_COMPONENTS,
        ...SCANNER_COMPONENTS,
        FormatFileSizePipe,
        LinkifyDirectivePipe,
        SanitizeHtmlPipe,
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ComponentsModule {
  static forRoot(): ModuleWithProviders<ComponentsModule> {
    return {
      ngModule: ComponentsModule,
    };
  }
}
