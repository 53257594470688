import { Injectable } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';

@Injectable()
export class GeolocationProvider {

    constructor() {}

    public async printCurrentPosition() {
        const coordinates = await Geolocation.getCurrentPosition();
    };
}
