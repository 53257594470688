import { Injectable } from '@angular/core';
import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GenericModalComponent } from '@components/modals/generic-modal/generic-modal.component';
import { CLOSE_BUTTON_MSG, ERROR_DEFAULT_MSG, ERROR_HEADER, SAD_FACE_PURPLE_ICON } from '@constants/utils.constants';

@Injectable()
export class ModalProvider {

  private activeAlert = {
    generic: false,
    customAction: false,
    customOnlyButton: false
  };

  constructor(
    private modalCtrl: ModalController,
  ) {}

  public async openModal() {
    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();
  }

  public async showErrorCustomActionOnlyOnButtonPressed(msg: string, customAction, button?: string, header?: string, backdropDismiss?: boolean) {
    if (!this.activeAlert.customOnlyButton) {
      this.activeAlert.customOnlyButton = true;
      const modal = await this.getCustomErrorModal(msg, header, button, backdropDismiss, customAction);
      modal.onDidDismiss().then(() => this.activeAlert.customOnlyButton = false);
      await modal.present();
    }
  }

  public async getCustomErrorModal(msg?: string, header?: string, button?: string, backdropDismiss?: boolean, customAction?) {
    return this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'modal-alert-custom',
      backdropDismiss,
      componentProps: {
        title: header || ERROR_HEADER,
        message: msg || ERROR_DEFAULT_MSG,
        primaryButtonText: button || CLOSE_BUTTON_MSG,
        icon: SAD_FACE_PURPLE_ICON,
        showCloseButton: false,
        primaryButtonAction: customAction
      }
    });
  }
}
