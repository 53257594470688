import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthFireService } from '@services/firebase/auth/auth-fire.service';
import { LocalStorageProvider } from '@providers/local-storage/local-storage';

@Injectable({
  providedIn: 'root'
})
export class EnrolledGuard implements CanActivate {

  constructor(
    private router: Router,
    private authFireSvc: AuthFireService,
    private localSvc: LocalStorageProvider,
  ) { }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userId = await this.localSvc.getUserId();
    const isSignedIn = await this.authFireSvc.isSignedIn();
    if (!isSignedIn || !userId) {
      await this.router.navigate(['auth']);
      return false;
    }
    return true;
  }
}
