import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Button } from '@models/general.model';
import { CustomForm } from '@models/custom-form.model';

@Component({
  selector: 'app-custom-form',
  templateUrl: './custom-form.component.html',
  styleUrls: ['./custom-form.component.scss'],
})
export class CustomFormComponent implements OnInit {
  @Input() formInputs: CustomForm[];
  @Input() buttonsOptions: boolean = true;
  @Input() onlyShow: boolean = false;
  @Input() firstButton: Button = { show: false, text: '' };
  @Input() secondButton: Button = { show: false, text: '' };

  public form: FormGroup;
  public loadingPage: boolean;
  public loading: boolean;

  @Output() validForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() data: EventEmitter<any> = new EventEmitter<any>();

  constructor(
      private formBuilder: FormBuilder,
  ) {}

  async ngOnInit() {
    this.loadingPage = true;
    this.formInputs.sort(function(a, b) {return a.order - b.order;});
    this._createForm();
    this.eventEmit();
    setTimeout(() => { this.loadingPage = false; }, 1000);
  }

  public eventEmit() {
    this.form.valueChanges.subscribe(() => {
      this.validForm.emit(this.form.valid);
      if (this.form.valid) this.data.emit(this.form.value);
    });
  }

  public isInputType(type: string) {
    return type === 'input';
  }
  public isSelectType(type: string) {
    return type === 'select';
  }

  private _createForm() {
    this.form = this.formBuilder.group({});
    this.formInputs.forEach((input) => {
      this.form.addControl(input.controlName, this.formBuilder.control('', input.required ? [Validators.required] : []));
    });
  }
}
