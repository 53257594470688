import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  constructor (
      private afs: AngularFirestore,
  ) {}

  public createRef(collection: string, docId: string) {
    return this.afs.collection(collection).doc(docId).ref;
  }

  public async get(doc: DocumentReference) {
    const document = await doc.get();
    return {docId: doc.id, ...document.data() as any};
  }

  public createBatch() {
    return this.afs.firestore.batch();
  }
}
