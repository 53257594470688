import { Injectable, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { UsersService } from '@services/firebase/users/users.service';
import { LocalStorageProvider } from '@providers/local-storage/local-storage';
import { User } from '@models/user.model';
import { AngularFirestore } from '@angular/fire/compat/firestore';

const collection: string = 'notifications';

@Injectable({
  providedIn: 'root'
})
export class WebNotificationsService implements OnChanges {
  public token = null;
  public userId: string;
  public notifications: any[] = [];

  constructor(
      private afs: AngularFirestore,
      private usersSvc: UsersService,
      private afMessaging: AngularFireMessaging,
      private localStorage: LocalStorageProvider,
  ) {
    this._getUser().then(() => {
      this.updateNotifications();
    });
  }

  get valid() { return this.token && this.userId; }

  async ngOnChanges(changes: SimpleChanges) {
    await this._getUser();
  }

  public async updateNotifications() {
    this.notifications = await this.getMessagesByUserId(this.userId);
  }

  public createRef(col: string, docId: string) {
    return this.afs.collection(col).doc(docId).ref;
  }

  public async markAsReaded(notifications: any[]) {
    if (notifications?.some(n => !n.readed)) {
      // never more than 30 notifications
      const batch = this.afs.firestore.batch();
      notifications.forEach(notification => {
        batch.set(notification.ref, { readed: true }, { merge: true });
        notification.readed = true;
      });
      await batch.commit();
    }
  }

  /*public requestPermission() {
    this._getUser().then(() => {
      return this.afMessaging.requestToken.pipe(
          tap(async token => {
            this.token = token; console.log('token', token);
            await this.localStorage.setPushToken(this.token);
            if (this.valid) await this.usersSvc.updateUser(this.userId, {lastToken: this.token} as User)
          })
      );
    });
  }*/

  public requestPermission() {
    this.afMessaging.requestPermission.subscribe(() => {
      this.afMessaging.getToken.subscribe((token) => {
        if (token) {
          this.token = token;
          if (this.userId) this.usersSvc.updateUser(this.userId, { lastToken: this.token } as User);
          else this.localStorage.setPushToken(this.token);
        }
      });
    })

    // this.afMessaging.requestPermission
    //     .pipe(mergeMapTo(this.afMessaging.tokenChanges))
    //     .subscribe(
    //         (token) => {
    //             if (this.userId && token) this.usersSvc.updateUser(this.userId, {lastToken: token} as User)
    //           },
    //         (error) => { console.error(error); },
    //     );
  }

  public async getMessagesByUserId(userId: string): Promise<any[]> {
    const userRef = this.createRef('users', userId);
    const querySnapshot = await this.afs.collection(collection).ref
        .where('user', '==', userRef)
        .orderBy('date', 'desc')
        .limit(30)
        .get();
    return querySnapshot.docs.map(doc => ({...doc.data() as any, ref: doc.ref}));
  }

  public getMessages() {
    return this.afMessaging.messages;
  }

  public deleteToken() {
    if (this.token) {
      this.afMessaging.deleteToken(this.token);
      this.token = null;
    }
  }

  private async _getUser() {
    this.userId = await this.localStorage.getUserId();
  }
}
