import { isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { LOCALE_ID } from '@angular/core';

import { environment } from '../environments/environment';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { ComponentsModule } from '@components/components.module';
import { provideFirebaseApp } from '@angular/fire/app';
import { initializeApp } from 'firebase/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { provideFirestore } from '@angular/fire/firestore';
import { getFirestore } from 'firebase/firestore';
import { provideRemoteConfig } from '@angular/fire/remote-config';
import { getRemoteConfig } from 'firebase/remote-config';
import { DEFAULTS, SETTINGS } from '@angular/fire/compat/remote-config';
import { ServicesModule } from '@services/services.module';
import { ProvidersModule } from '@providers/providers.module';
import { DatePipe } from '@angular/common';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { registerLocaleData } from '@angular/common';
import localeCL from '@angular/common/locales/es-CL';
registerLocaleData(localeCL);

@NgModule({
  declarations: [AppComponent],
  imports: [
      BrowserModule,
      ComponentsModule,
      HttpClientModule,
      IonicModule.forRoot(),
      AppRoutingModule,
      ServiceWorkerModule.register('combined-sw.js', { enabled: environment.production }),
      AngularFireModule.initializeApp(environment.firebase),
      provideFirebaseApp(() => initializeApp(environment.firebase)),
      provideRemoteConfig(() => getRemoteConfig()),
      provideAuth(() => getAuth()),
      provideFirestore(() => getFirestore()),
      AngularFireMessagingModule,
      GooglePlaceModule,
  ],
  providers: [
      { provide: LOCALE_ID, useValue: "es-CL" },
      { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
      { provide: DEFAULTS, useValue: { enableAwesome: true } },
      { provide: SETTINGS, useFactory: () => isDevMode() ? { minimumFetchIntervalMillis: 10 } : environment.remoteConfig},
      ...ServicesModule.forRoot().providers,
      ...ProvidersModule.forRoot().providers,
      DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
