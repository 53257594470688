import { Component, Input } from '@angular/core';
import { UtilsService } from '@services/utils/utils.service';


@Component({
  selector: 'app-redeem-product-card',
  templateUrl: './redeem-product-card.component.html',
  styleUrls: ['./redeem-product-card.component.scss'],
})
export class RedeemProductCardComponent {

  @Input() product: any;
  @Input() fullSize: boolean = false;
  
  get loading() { return Boolean(!this.product) }
  get isDiscount() { return Boolean(this.product?.maxDiscountPercentage && this.product?.price) }
  get appliedDiscountPrice() { return this.product?.price - (this.product?.price * this.product?.maxDiscountPercentage / 100) }

  constructor (
    private utils: UtilsService,
  ) {}

  getScssUrl(url: string) {
    return this.utils.getScssUrl(url)
  }

}
