import { Injectable } from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';

@Injectable({
    providedIn: 'root'
})
export class RemoteConfigService {
    constructor (
        private remoteConfig: AngularFireRemoteConfig,
    ) {
        this._init();
    }

    private async _init() {
        await this.remoteConfig.fetchAndActivate();
    }

    public async getBooleanValue(key: string): Promise<boolean> {
        await this.remoteConfig.fetch();
        return await this.remoteConfig.getBoolean(key);
    }

    public async getStringValue(key: string): Promise<string> {
        await this.remoteConfig.fetch();
        return await this.remoteConfig.getString(key);
    }
}
