import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { MenuConstants } from '@constants/menu.constants';
import { Router } from "@angular/router";
import { WebNotificationsService } from '@services/firebase/web-notifications/web-notifications.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent  implements OnInit {
  @Input() title;
  @Input() startIcon;
  @Input() endIcon;
  @Input() lateralMenu: boolean;
  @Input() notifications: boolean;
  @Input() id: string;

  @Output() startIconClick = new EventEmitter();
  @Output() endIconClick = new EventEmitter();

  public menuItems = MenuConstants;

  get headerNotifications() { return this.webNotificationsSvc.notifications; }
  get showBullet() { return this.headerNotifications?.some(n => !n.readed); }

  constructor(
      private menu: MenuController,
      private webNotificationsSvc: WebNotificationsService,
      private router: Router,
  ) {}

  async ngOnInit () {
    this.menuItems.sort((a, b) => a.order - b.order);
  }

  public async clickOnStart() {
    if (this.lateralMenu) await this.openMenu();
    this.startIconClick.emit();
  }

  public async clickOnEnd() {
    if (this.notifications) await this.openNotifications();
    this.endIconClick.emit();
  }

  public async openMenu() {
    await this.menu.enable(true, `main-menu${this.id}`);
    await this.menu.open(`main-menu${this.id}`);
  }

  public async openNotifications() {
    this.webNotificationsSvc.markAsReaded(this.headerNotifications);
    await this.menu.enable(true, `main-menu2${this.id}`);
    await this.menu.open(`main-menu2${this.id}`);
  }

  public clickNotification(notification) {
    notification.ref.set({ clicked: true }, { merge: true });
    this.webNotificationsSvc.notifications.find(n => n.ref.id === notification.ref.id).clicked = true;
    notification.clicked = true;
    switch (notification.type) {
      case 'chat':
        this.router.navigate(['enrolled', 'tabs', 'chat']);
        this.menu.close(`main-menu2${this.id}`);
        break;
      case 'service':
        this.router.navigate(['enrolled', 'tabs', 'withdrawals', 'service'], { queryParams: { docId: notification.serviceId } });
        this.menu.close(`main-menu2${this.id}`);
        break;
      default:
        break;
    }
  }
}
